import React from "react";
import { BiError } from "react-icons/bi";

const Confirm = ({ title, message, onConfirm, onCancel }) => {
    return (
        <main className="bg-gray-200 text-gray-900 font-sans absolute inset-0 overflow-x-hidden antialiased">
            <div className="relative min-h-screen px-4 flex items-center justify-center">
                <div className="absolute inset-0 z-50 h-full w-full bg-black opacity-25"></div>
                <div className="inset-x-0 bottom-0 z-50 mb-4 rounded bg-white p-4 relative mx-auto max-w-md">
                    <div className="items-center md:flex">
                        <div className="border-gray-300 mx-auto flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full border">
                            <BiError size={38} />
                        </div>
                        <div className="mt-4 text-center md:ml-6 md:mt-0 md:text-left">
                            <p className="font-bold">{title}</p>
                            <p className="text-gray-700 mt-1 text-sm">{message}</p>
                        </div>
                    </div>
                    <div className="mt-4 text-center md:flex md:justify-end md:text-right">
                        <button className="block w-full rounded bg-red-200 px-4 py-3 text-sm font-semibold text-red-700 md:order-2 md:ml-2 md:inline-block md:w-auto md:py-2" onClick={onConfirm}>
                            Confirm
                        </button>
                        <button className="bg-gray-200 mt-4 block w-full rounded px-4 py-3 text-sm font-semibold md:order-1 md:mt-0 md:inline-block md:w-auto md:py-2" onClick={onCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Confirm;
