import React from "react";
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signInWithCustomToken, signOut } from "firebase/auth";
import { Form, redirect, useActionData, useLoaderData } from "react-router-dom";

import { Button, Input } from "../components";
import { ReactComponent as LoginIllustration } from "../assets/login.svg";
import { ReactComponent as LogoIllustration } from "../assets/logo-login.svg";

export const action = async ({ request }) => {
    const formData = await request.formData();
    const { email } = Object.fromEntries(formData);

    let baseUrl = "https://us-central1-plateforme-matchmaking-fd.cloudfunctions.net/api";
    if (document.location.hostname === "localhost") baseUrl = "http://127.0.0.1:5001/plateforme-matchmaking-fd/us-central1/api";


    return await fetch(`${baseUrl}/login/send?email=${email}&link=${encodeURIComponent(`${window.location.origin}/login/confirm?email=${email}`)}`)
        .then((response) => response.json())
        .then((data) => {
            if (data.success) return { success: true };
            else return { error: data.msg };
        })
        .catch((error) => {
            console.error({ error });
            return { error: "An error occured while sending the email" };
        });
};

export const loader = async ({ params }) => {
    const { finish } = params;
    const url = new URL(window.location.href);
    const auth = getAuth();

    const token = url.searchParams.get("token");
    if (token) {
        if (auth.currentUser) await signOut(auth);
        return await signInWithCustomToken(auth, token)
            .then(() => redirect("/"))
            .catch((error) => ({ error: error.message }));
    }

    await auth.authStateReady();
    if (auth.currentUser) return redirect("/");

    if (!!finish) {
        const email = url.searchParams.get("email");
        if (!email) return redirect(`/login`);
        localStorage.setItem("emailForSignIn", email);

        if (isSignInWithEmailLink(auth, window.location.href))
            return await signInWithEmailLink(auth, email, window.location.href)
                .then(() => redirect("/"))
                .catch((error) => ({ error: error.message }));
        else return { error: "Invalid link" };
    }
    return {};
};

const Login = () => {
    const loader = useLoaderData();
    const action = useActionData();

    const [error, setError] = React.useState(null);
    const [success, setSuccess] = React.useState(null);

    React.useEffect(() => {
        if (loader?.error) setError(loader.error);
        if (action?.error) setError(action.error);
        if (action?.success) setSuccess(action.success);
    }, [loader, action]);

    return (
        <section className="flex h-screen flex-1 items-center justify-center bg-blue p-4">
            <div className="z-10 flex flex-col items-center gap-[52px]">
                <div className="flex flex-col gap-4 text-center">
                    <h1 className="text-center text-6xl text-white">Welcome to our matchmaking space</h1>
                    <p className="text-lg font-semibold	leading-6 text-white">Powered by France Digitale</p>
                </div>
                <Form method="post" className="z-1 flex w-full flex-col items-center gap-4">
                    <Input placeholder="email@domain.com" name="email" id="email" className="md:w-[702px]" />
                    <Button className="bg-red-400 md:w-[702px]">Generate my link to login</Button>
                    {error && (
                        <div className="relative flex gap-1 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
                            <span className="font-bold">Error!</span>
                            <span className="block sm:inline">{error}</span>
                            <span onClick={() => setError(null)}>
                                <svg className="h-6 w-6 fill-current text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <title>Close</title>
                                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                </svg>
                            </span>
                        </div>
                    )}
                    {success && (
                        <div className="relative flex gap-1 rounded border border-green-400 bg-green-100 px-4 py-3 text-green-700" role="alert">
                            <span className="font-bold">Success!</span>
                            <span className="block sm:inline">An email has been sent to you with a link to login</span>
                            <span className="">
                                <svg className="h-6 w-6 fill-current text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <title>Close</title>
                                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                </svg>
                            </span>
                        </div>
                    )}
                </Form>
                <div>
                    <LogoIllustration height={85} width={236} />
                </div>
            </div>
            <div className="pointer-events-none absolute bottom-0 right-0 w-2/4">
                <LoginIllustration width={"100%"} />
            </div>
        </section>
    );
};

export default Login;
