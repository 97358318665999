// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAP-swfFs-oUoPlwYmGyphHdriYrF9imco",
    authDomain: "plateforme-matchmaking-fd.firebaseapp.com",
    projectId: "plateforme-matchmaking-fd",
    storageBucket: "plateforme-matchmaking-fd.appspot.com",
    messagingSenderId: "553487942881",
    appId: "1:553487942881:web:245cd85fb8743cc623e3a0",
    measurementId: "G-KTZ1HVQSHZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
// const analytics = getAnalytics(app);

export { db, storage };
