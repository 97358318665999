export const Button = ({ type, children, onClick, className }) => {
    return <button
        type={type ? type : "button"}
        onClick={onClick ? onClick : () => { }}
        className={`inline-block rounded bg-blue px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong ${className}`}>
        {children}
    </button>
}

export const IconButton = ({ type, children, onClick, className }) => {
    return <button
        type={type ? type : "button"}
        onClick={onClick ? onClick : () => { }}
        className={`${className}`}>
        {children}
    </button>
}

export const RoundIconButton = ({ children, onClick, className = "" } = {}) => {
    return (
        <button onClick={onClick} className={"hover:bg-opacity-90 w-12 h-12 rounded-full flex items-center justify-center text-lg bg-white border-0 active:bg-opacity-80 " + className}>
            {children}
        </button>
    );
}