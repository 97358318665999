import * as React from "react";
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";

import { getAuth, signOut } from "firebase/auth";

import ErrorPage from "../pages/Error";

// user pages
import LoginPage, { action as loginAction, loader as loginLoader } from "../pages/Login";
import HomePage from "../pages/Home";
import Profile, { action as profileAction, loader as profileLoader } from "../pages/Profile";
import Company, { action as companyAction, loader as companyLoader } from "../pages/Company";
import EventOutlet, { action as eventAction, loader as eventLoader } from "../pages/Event";
import Attendees from "../pages/Event/Attendees";
import Schedule from "../pages/Event/Schedule";

import DrawerLayout, { loader as drawerLoader } from "../pages/Layout/Drawer";
import AdminDrawer, { loader as adminDrawerLoader } from "../pages/Admin/Layout/AdminDrawner";

// admin pages
import AdminHomePage from "../pages/Admin/Home/";
import { Users as AdminUsersManagement, User as AdminUserManagement } from "../pages/Admin/Users/";
import { Companies as AdminCompaniesManagement, Company as AdminCompanyManagement } from "../pages/Admin/Companies/";
import { Filters as AdminFiltersManagement, Filter as AdminFilterManagement } from "../pages/Admin/Filters/";
import { Events as AdminEventsManagement, Event as AdminEventManagement } from "../pages/Admin/Events/";
import { Meetings as AdminMeetingsManagement } from "../pages/Admin/Events/Meetings";

const userRouter = [
    {
        children: [
            {
                path: "/",
                id: "drawer-layout",
                element: <DrawerLayout />,
                children: [
                    {
                        path: "/",
                        element: <HomePage />,
                    },
                    {
                        path: "/profile",
                        element: <Profile />,
                        action: profileAction,
                        loader: profileLoader,
                    },
                    {
                        path: "/company",
                        element: <Company />,
                        action: companyAction,
                        loader: companyLoader,
                    },
                    {
                        path: "/events/:eventId",
                        element: <EventOutlet />,
                        action: eventAction,
                        loader: eventLoader,
                        children: [
                            {
                                path: "/events/:eventId/attendees",
                                element: <Attendees />,
                            },
                            {
                                path: "/events/:eventId/wishlist",
                                element: <Attendees />,
                            },
                            {
                                path: "/events/:eventId/schedule",
                                element: <Schedule />,
                            },
                        ],
                    },
                ],
                loader: drawerLoader,
            },
            {
                path: "/login/:finish?",
                element: <LoginPage />,
                action: loginAction,
                loader: loginLoader,
            },
            {
                path: "/logout",
                loader: async () => {
                    const auth = getAuth();
                    await signOut(auth);
                    return redirect("/login");
                },
                element: null,
            },
        ],
        errorElement: <ErrorPage />,
    },
];

const adminRouter = [
    {
        children: [
            {
                path: "/",
                element: <AdminDrawer />,
                children: [
                    {
                        path: "/",
                        element: <AdminHomePage />,
                    },
                    {
                        path: "/users",
                        element: <AdminUsersManagement />,
                    },
                    {
                        path: "/users/:userId",
                        element: <AdminUserManagement />,
                    },
                    {
                        path: "/companies",
                        element: <AdminCompaniesManagement />,
                    },
                    {
                        path: "/companies/:companyId",
                        element: <AdminCompanyManagement />,
                    },
                    {
                        path: "/filters",
                        element: <AdminFiltersManagement />,
                    },
                    {
                        path: "/filters/:filterId",
                        element: <AdminFilterManagement />,
                    },
                    {
                        path: "/events",
                        element: <AdminEventsManagement />,
                    },
                    {
                        path: "/events/:eventId",
                        element: <AdminEventManagement />,
                    },
                    {
                        path: "/events/:eventId/meetings",
                        element: <AdminMeetingsManagement />,
                    },
                ],
                loader: adminDrawerLoader,
            },
            {
                path: "/login/:finish?",
                element: <LoginPage />,
                action: loginAction,
                loader: loginLoader,
            },
        ],
        errorElement: <ErrorPage />,
    },
];

function AppRouter() {
    /*
     * By default subdomain is null and it will automatically detect the subdomain
     * If the subdomain is local, it will use the userRouter
     * If the subdomain is admin, it will use the adminRouter
     */

    const [subdomain, setSubdomain] = React.useState(null);

    React.useEffect(() => {
        if (subdomain !== null) return;
        const hostname = window.location.hostname;

        if (["127.0.0.1", "localhost"].some((str) => hostname.includes(str))) {
            setSubdomain("local");
            return;
        }

        const arr = hostname.split(".").at(0);
        if (arr) setSubdomain(arr);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (subdomain === null) return null;

    return <RouterProvider router={createBrowserRouter(subdomain === "admin" ? adminRouter : userRouter)} />;
}

export default AppRouter;
