import React, { useEffect, useState } from "react";
import { Form, NavLink, useActionData, useLoaderData, useOutletContext, useBlocker, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { collection, doc, getDoc, getDocs, setDoc, deleteField, query, collectionGroup, where } from "firebase/firestore";
import { db } from "../firebase";

import { IoIosAdd } from "react-icons/io";

import { Back, Button, Confirm, Input, Toast } from "../components";
import { ReactComponent as BackIllustration } from "../assets/arrow-right.svg";

export const action = async ({ request }) => {
    const auth = getAuth();
    await auth.authStateReady();

    const formData = await request.formData();
    let companyRef;
    const { firstname, lastname, phone, role, company } = Object.fromEntries(formData);

    // check if name, lastname, phone, role are not empty
    if (!firstname || !lastname || !phone || !role) return { error: "Please fill all the fields" };
    const userDocRef = doc(db, "users", auth.currentUser.uid);

    if (company) {
        const EventsTransferred = {};
        // get current company Event and remove user from it
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists() && userDocSnapshot.data().company) {
            const currDocSnap = await getDoc(userDocSnapshot.data().company);
            if (currDocSnap.exists()) {
                const compData = currDocSnap.data();
                const events = Object.keys(compData.events || {}).filter((event) => compData.events[event].users.find((_user) => _user.user === auth.currentUser.uid));
                for (let i = 0; i < events.length; i++) {
                    const event = events[i];
                    EventsTransferred[event] = compData.events[event].users.find((_user) => _user.user === auth.currentUser.uid);
                    compData.events[event].users = compData.events[event].users.filter((_user) => _user.user !== auth.currentUser.uid);
                }
                await setDoc(currDocSnap.ref, compData, { merge: true });
            }
        }

        // get all events and a new
        const eventRef = query(collectionGroup(db, "users"), where("email", "==", auth.currentUser.email));
        const querySnapshot = await getDocs(eventRef);
        for (const doc of querySnapshot.docs) {
            if (doc.id !== auth.currentUser.email) continue;
            const event = await getDoc(doc.ref.parent.parent);
            EventsTransferred[event.id] = { email: auth.currentUser.email, user: auth.currentUser.uid, type: doc.data().type };
        }

        console.log(EventsTransferred);

        // add user to new company
        const docRef = doc(db, "companies", company);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            companyRef = docRef;
            const companyData = docSnap.data();
            if (!companyData.events) companyData.events = {};
            let companyType = null;
            let keys = Object.keys(EventsTransferred);
            for (let i = 0; i < keys.length; i++) {
                const event = keys[i];
                if (!companyData.events[event]) companyData.events[event] = { users: [] };
                companyData.events[event].users.push(EventsTransferred[event]);
                companyType = EventsTransferred[event].type;
            }
            if (companyType) companyData.type = companyType;
            await setDoc(docRef, companyData, { merge: true });
        }
    }

    await setDoc(userDocRef, { firstname, lastname, phone, role, company: companyRef, email: company ? auth.currentUser.email : deleteField() }, { merge: true });
    return { success: "Profile updated" };
};

export const loader = async () => {
    const colRef = collection(db, "companies");
    const querySnapshot = await getDocs(colRef);
    const companies = [];
    querySnapshot.forEach((doc) => companies.push({ id: doc.id, name: doc.data().name, website: doc.data().website }));

    return { companies };
};

const Profile = () => {
    const navigate = useNavigate();
    const action = useActionData();
    const { user: userOutlet } = useOutletContext();
    const { companies } = useLoaderData();
    const [user, setUser] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [company, setCompany] = useState({});
    const [companyQuery, setCompanyQuery] = useState("");
    const [autoComplete, setAutoComplete] = useState(false);

    let blocker = useBlocker(({ currentLocation, nextLocation }) => {
        let areChanges =
            document.forms["profile"]?.firstname.value !== userOutlet?.firstname ||
            document.forms["profile"]?.lastname.value !== userOutlet?.lastname ||
            document.forms["profile"]?.phone.value !== userOutlet?.phone ||
            document.forms["profile"]?.role.value !== userOutlet?.role ||
            document.forms["profile"]?.company[0].value !== userOutlet?.company?.id;

        return currentLocation.pathname !== nextLocation.pathname && !nextLocation.search.startsWith("?from=profile&create=") && areChanges;
    });

    useEffect(() => {
        if (localStorage.getItem("unsaved")) {
            const rest = JSON.parse(localStorage.getItem("unsaved"));
            document.forms["profile"].firstname.value = rest.firstname;
            document.forms["profile"].lastname.value = rest.lastname;
            document.forms["profile"].phone.value = rest.phone;
            document.forms["profile"].role.value = rest.role;
            localStorage.removeItem("unsaved");
        }
    }, []);

    useEffect(() => {
        setUser(userOutlet);
        if (userOutlet.company) {
            const com = companies.find((c) => `companies/${c.id}` === userOutlet.company.path);
            if (!com) return;
            setCompany({ ...com, path: `companies/${com.id}` });
            setCompanyQuery(com.name);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userOutlet]);

    useEffect(() => {
        if (action?.error) setError(action.error);
        if (action?.success) {
            setSuccess(action.success);
            setSubmit(false);
            document.forms["profile"].reset();
        }
    }, [action]);

    const companiesFiltered = companies.filter((c) => c.name.toLowerCase().includes(companyQuery.trim().toLowerCase()));
    return (
        <div>
            <Form className="flex flex-col gap-8" name="profile" method="post">
                <div className="flex items-center gap-6">
                    <Back to="/" />
                    <h2 className="text-5xl text-blue">{user?.isNew ? "Create" : "Edit"} my profile</h2>
                </div>
                <div className="sm:mx-16">
                    <div className="grid grid-flow-row grid-cols-1 gap-y-8 md:grid-cols-2">
                        <div className="flex flex-col gap-1 md:mr-28">
                            <label htmlFor="firstname" className="text-lg font-semibold">
                                First name
                            </label>
                            <Input required id="firstname" name="firstname" placeholder="Your name" className="bg-beige-light" defaultValue={user?.firstname} />
                        </div>
                        <div className="flex flex-col gap-1 md:mr-28">
                            <label htmlFor="lastname" className="text-lg font-semibold">
                                Last name
                            </label>
                            <Input required id="lastname" name="lastname" placeholder="Your last name" className="bg-beige-light" defaultValue={user?.lastname} />
                        </div>
                        <div className="flex flex-col gap-1 md:mr-28">
                            <label htmlFor="phone" className="text-lg font-semibold">
                                Phone number
                            </label>
                            <Input required id="phone" name="phone" placeholder="0611223344" className="bg-beige-light" defaultValue={user?.phone} />
                        </div>
                        <div className="flex flex-col gap-1 md:mr-28">
                            <label htmlFor="role" className="text-lg font-semibold">
                                Position
                            </label>
                            <Input required id="role" name="role" placeholder="Your role" className="bg-beige-light" defaultValue={user?.role} />
                        </div>
                    </div>
                </div>
                <div className="sm:mx-16">
                    <h4 className="text-2xl">Company</h4>
                    <div className="flex flex-col gap-1">
                        <label htmlFor="company" className="text-lg font-semibold">
                            Find your company or create it
                        </label>
                        <div className="flex flex-col gap-4 md:flex-row md:items-center md:gap-2">
                            <div className="relative flex-1">
                                <input required type="hidden" name="company" value={company?.id || ""} />
                                <Input
                                    id="company"
                                    placeholder="Type the name of your company here"
                                    className="bg-beige-light"
                                    onBlur={async (e) => {
                                        setTimeout(function () {
                                            setAutoComplete(false);
                                        }, 250);
                                    }}
                                    onFocus={() => setAutoComplete(true)}
                                    onChange={(e) => {
                                        setCompanyQuery(e.target.value);
                                    }}
                                    value={companyQuery}
                                    defaultValue={company.name}
                                />
                                {autoComplete && companyQuery && (
                                    <ul className="rounded—lg absolute mt-1 max-h-[200px] w-full overflow-y-auto rounded bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                                        {companiesFiltered.map((_company, idx) => (
                                            <div key={`search_${_company.id}`}>
                                                <li
                                                    className="min-h-10 w-full cursor-pointer p-5 py-3 leading-normal hover:bg-grey-200"
                                                    onClick={() => {
                                                        setCompanyQuery(_company.name);
                                                        setCompany(_company);
                                                    }}
                                                >
                                                    {_company.name} {`(${_company.website})`}
                                                </li>
                                                {idx !== companiesFiltered.length - 1 && <hr className="ml-[5%] mr-[5%] border-grey-300 bg-green-600" />}
                                            </div>
                                        ))}
                                        {!companiesFiltered.length && (
                                            <li
                                                key={"create_company"}
                                                className="flex min-h-10 w-full cursor-pointer gap-1 p-5 py-3 leading-normal hover:bg-grey-200"
                                                onClick={() => {
                                                    let areChanges =
                                                        document.forms["profile"]?.firstname.value !== userOutlet?.firstname ||
                                                        document.forms["profile"]?.lastname.value !== userOutlet?.lastname ||
                                                        document.forms["profile"]?.phone.value !== userOutlet?.phone ||
                                                        document.forms["profile"]?.role.value !== userOutlet?.role ||
                                                        document.forms["profile"]?.company[0].value !== userOutlet?.company?.id;
                                                    if (areChanges) {
                                                        localStorage.setItem(
                                                            "unsaved",
                                                            JSON.stringify({
                                                                firstname: document.forms["profile"].firstname.value,
                                                                lastname: document.forms["profile"].lastname.value,
                                                                phone: document.forms["profile"].phone.value,
                                                                role: document.forms["profile"].role.value,
                                                            }),
                                                        );
                                                    }
                                                    navigate(`/company?from=profile&create=${companyQuery}`);
                                                }}
                                            >
                                                <IoIosAdd size={24} />
                                                Create company "{companyQuery.trim()}"
                                            </li>
                                        )}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:mx-16">
                    <Button className={`flex items-center justify-center ${success ? "bg-green-400" : "bg-blue"} text-white`} loading={submit} disabled={submit}>
                        {success ? "Info successfully saved !" : "Save"}
                    </Button>
                </div>
                {user.company && user.email && (
                    <div className="sm:mx-16">
                        <NavLink to="/company?from=profile">
                            <Button type="button" className="flex items-center justify-center gap-3 bg-orange text-white">
                                Update my company profile <BackIllustration className="rotate-180" />
                            </Button>
                        </NavLink>
                    </div>
                )}
            </Form>
            {blocker.state === "blocked" && <Confirm title="Unsaved changes" message="Are you sure you want to leave this page without saving your changes?" onCancel={() => blocker.reset()} onConfirm={() => blocker.proceed()} />}
            <Toast message={error} color="red-400" disabled={!error} finish={() => setError(false)} />
            <Toast message={success} color="green-400" disabled={!success} finish={() => setSuccess(false)} />
        </div>
    );
};

export default Profile;
