import React from "react";
import { useDropzone } from "react-dropzone";
import Button from "./Button";

const Dropzone = ({ accept, placeholder, handleFile, setError, name, className = "", ...props } = {}) => {
    const { getRootProps, getInputProps, open } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        maxFiles: 1,
        accept: accept,
        useFsAccessApi: false,
        onDropAccepted: (e) => handleFile && handleFile(e),
        onDropRejected: (e) => (setError ? setError(e[0].errors[0].message) : console.log(e[0].errors[0].message)),
    });

    return (
        <div {...getRootProps({ className: `dropzone py-14 gap-2.5 flex justify-center items-center flex-col border-black border-2 border-dashed rounded-lg ${className}` })} {...props}>
            <input {...getInputProps()} name={name} id={name} />
            <p className="font-inter text-sm text-grey-700">{placeholder}</p>
            <Button type="button" className="!w-auto bg-blue text-white" onClick={open}>
                Choose file
            </Button>
        </div>
    );
};

export default Dropzone;
