import React from "react";
import ReactDOM from "react-dom/client";

import "./firebase";
import "./index.css";
import AppRouter from "./routers";

const root = ReactDOM.createRoot(document.getElementById("root"));

window.fdVariables = {
    algolia: {
        appId: "B2L8WIFZEG",
        apiKey: "072d383e2085e7e8cb7c0171edc13188",
    },
}

root.render(
    // <React.StrictMode>
        <AppRouter />
    // </React.StrictMode>,
);
