import React, { useEffect, useState } from "react";
import { Form, redirect, useActionData, useLoaderData, useOutletContext } from "react-router-dom";

import { getAuth } from "firebase/auth";
import { collection, doc, setDoc, getDocs, getDoc, updateDoc, deleteField } from "firebase/firestore";
import { ref, uploadBytes, deleteObject, getBlob, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase";

import { Back, Button, Input, Toast, Dropzone } from "../components";

import { ReactComponent as CrossIllustration } from "../assets/cross.svg";

export const patterns = {
    number: {
        pattern: "[0-9]*",
        regex: /[0-9]/,
    },
    text: "",
};

// const uploadProfilePicture = async (file) => {
//     const auth = getAuth();
//     await auth.authStateReady();

//     const userDocRef = doc(db, "users", auth.currentUser.uid);
//     const userDocSnapshot = await getDoc(userDocRef);
//     let previousFiles;
//     let companyDocRef;

//     if (userDocSnapshot.exists() && userDocSnapshot.data().company) {
//         companyDocRef = userDocSnapshot.data().company;
//         const currDocSnap = await getDoc(companyDocRef);

//         if (currDocSnap.exists()) {
//             previousFiles = currDocSnap.data().logo?.ref;
//         }
//     }

//     let newFileRefs = deleteField();
//     if (file && file.size !== 0) {
//         const filePath = `company/${companyDocRef.id}/${file.name}`;
//         const fileRef = ref(storage, filePath);
//         await uploadBytes(fileRef, file);
//         newFileRefs = {
//             ref: filePath,
//             name: file.name,
//             owner: userDocRef,
//         };

//         newFileRefs.url = await getDownloadURL(fileRef);
//     }

//     if (previousFiles) {
//         const prevFileRef = ref(storage, previousFiles);
//         await deleteObject(prevFileRef).catch((error) => {
//             console.error("Failed to delete old file:", error);
//         });
//     }

//     if (newFileRefs) await setDoc(companyDocRef, { logo: newFileRefs }, { merge: true });
// };

export const action = async ({ request }) => {
    const auth = getAuth();
    await auth.authStateReady();

    const formData = Object.fromEntries(await request.formData());
    const { logo, pitch, pitch_delete, logo_delete, ...data } = formData; // Exclure les fichiers des données textuelles

    const url = new URL(request.url);
    const from = url.searchParams.get("from") || "company";
    const create = url.searchParams.get("create");

    if (!Object.values(data).every((value) => value !== null && value !== undefined)) {
        return { error: "Please fill all the fields" };
    }

    data.filters = {};
    for (const key in data) {
        if (key.startsWith("filter_")) {
            data.filters[key.split("_")[1]] = data[key];
            delete data[key];
        }
    }

    const userDocRef = doc(db, "users", auth.currentUser.uid);
    const userDocSnapshot = await getDoc(userDocRef);
    let companyDocRef;
    let previousFiles = {};

    if (!create && userDocSnapshot.exists() && userDocSnapshot.data().company) {
        companyDocRef = userDocSnapshot.data().company;
        const currDocSnap = await getDoc(companyDocRef);

        if (currDocSnap.exists()) {
            previousFiles = {
                logo: currDocSnap.data().logo?.ref,
                pitch: currDocSnap.data().pitch?.ref,
            };
        }

        await updateDoc(companyDocRef, data);
    } else {
        const companyCollectionRef = collection(db, "companies");
        companyDocRef = doc(companyCollectionRef);
        await setDoc(companyDocRef, data);
    }
    await setDoc(userDocRef, { company: companyDocRef }, { merge: true });

    const newFileRefs = {};
    if (pitch_delete && previousFiles.pitch) {
        const pitchRef = ref(storage, previousFiles.pitch);
        await deleteObject(pitchRef).catch((error) => {
            console.error("Failed to delete pitch:", error);
        });
        newFileRefs.pitch = deleteField();
    }

    if (logo_delete && previousFiles.logo) {
        const logoRef = ref(storage, previousFiles.logo);
        await deleteObject(logoRef).catch((error) => {
            console.error("Failed to delete logo:", error);
        });
        newFileRefs.logo = deleteField();
    }

    for (const field of ["logo", "pitch"]) {
        const file = formData[field];
        if (file && file.size > 0) {
            const filePath = `company/${companyDocRef.id}/${file.name}`;
            const fileRef = ref(storage, filePath);
            await uploadBytes(fileRef, file);
            newFileRefs[field] = {
                ref: filePath,
                name: file.name,
                owner: userDocRef,
            };

            if (field === "logo") newFileRefs[field].url = await getDownloadURL(fileRef);
            if (previousFiles[field]) {
                const prevFileRef = ref(storage, previousFiles[field]);
                await deleteObject(prevFileRef).catch((error) => {
                    console.error("Failed to delete old file:", error);
                });
            }
        }
    }

    await setDoc(companyDocRef, newFileRefs, { merge: true });

    if (create) return redirect(`/${from}?company=${companyDocRef.id}`);
    return { success: "Company Profile updated" };
};

export const loader = async ({ request }) => {
    const colRef = collection(db, "filters");
    const querySnapshot = await getDocs(colRef);
    const filters = [];
    querySnapshot.forEach((doc) => filters.push({ ...doc.data(), id: doc.id }));

    const url = new URL(request.url);
    const from = url.searchParams.get("from");
    const create = url.searchParams.get("create");

    return { filters, create, from };
};

const Company = () => {
    const action = useActionData();
    const { user: userOutlet } = useOutletContext();
    const { filters, create, from } = useLoaderData();

    const [submit, setSubmit] = useState(false);
    const [download, setDownload] = useState(false);
    const [company, setCompany] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleLogo = (files) => {
        if (!files) {
            setCompany((p) => ({ ...p, logo_delete: true, logo: undefined }));
            // uploadProfilePicture(null);
            return;
        }
        setCompany((p) => ({ ...p, logo_delete: false, logo: { preview: URL.createObjectURL(files[0]), ...files[0] } }));
        // uploadProfilePicture(files[0]);
    };

    const handlePitch = (files) => {
        setCompany((p) => ({ ...p, pitch_delete: false, pitch_new: files[0] }));
    };

    useEffect(() => {
        const getCompany = async () => {
            const doc = await getDoc(userOutlet.company);
            if (!doc.exists()) return;
            setCompany(doc.data());
        };

        if (create) {
            setCompany({ name: create });
            return;
        }
        if (!userOutlet.company) return;
        getCompany();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userOutlet]);

    useEffect(() => {
        if (action?.error) setError(action.error);
        if (action?.success) {
            setSuccess(action.success);
            setSubmit(false);
            document.forms["company"].reset();
        }
    }, [action]);

    const downloadPitch = async () => {
        setDownload(true);
        try {
            if (!company.pitch || !company.pitch.ref) return;
            if (company.pitch.owner.path !== `users/${userOutlet.id}`) {
                setError("You are not authorized to download this pitch");
                return;
            }
            const blob = await getBlob(ref(storage, company.pitch.ref));
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = company.pitch.name;
            a.click();
        } catch (error) {
            console.error("Failed to download pitch:", error);
            setError("Failed to download pitch");
        }
        setDownload(false);
    };

    return (
        <div>
            <Form name="company" className="flex flex-col gap-8" method="post" encType="multipart/form-data" onSubmit={() => setSubmit(true)}>
                <div className="flex gap-6">
                    <Back to={`/${from || ""}`} />
                    <div>
                        <h2 className="text-5xl text-blue">{create ? "Create" : "Edit"} my company</h2>
                        <h2 className="text-4xl text-black">{company.name}</h2>
                    </div>
                </div>
                <div className="sm:mx-16">
                    <div className="grid grid-flow-row grid-cols-1 gap-8 lg:grid-cols-6">
                        <div className={`${!company.logo && "hidden"} relative col-span-3 row-span-4 flex max-h-[400px] items-center justify-center rounded-lg border-2 border-dashed border-black`}>
                            <div className="absolute -right-4 -top-4 flex h-8 w-8 -rotate-2 transform cursor-pointer items-center justify-center bg-blue" onClick={() => handleLogo()}>
                                <CrossIllustration className="rotate-2 transform" />
                            </div>
                            <img src={company.logo?.preview || company.logo?.url} onLoad={() => URL.revokeObjectURL(company.logo?.preview)} alt="logo" className="block h-full w-auto object-contain" />
                        </div>
                        <input type="hidden" name="logo_delete" value={company.logo_delete ? true : ""} />
                        <Dropzone
                            className={`${company.logo && "hidden"} col-span-3 row-span-4`}
                            handleFile={handleLogo}
                            setError={setError}
                            placeholder="Drag and drop your logo or"
                            name="logo"
                            accept={{
                                "image/jpeg": [],
                                "image/png": [],
                                "image/webp": [],
                            }}
                        />
                        <div className="col-span-3 flex flex-col gap-y-1">
                            <label htmlFor="name" className="text-base">
                                Company name*
                            </label>
                            <Input required id="name" name="name" placeholder="Name of your company" className="bg-beige-light text-sm" defaultValue={company?.name} />
                        </div>
                        <div className="col-span-3 flex flex-col gap-y-1">
                            <label htmlFor="website" className="text-base">
                                Company website*
                            </label>
                            <Input required id="website" name="website" placeholder="www.your-website.com" className="bg-beige-light text-sm" defaultValue={company?.website} />
                        </div>
                        <div className="col-span-3 row-span-2 flex flex-col gap-y-1">
                            <label htmlFor="desc" className="text-base">
                                Description of your company*
                            </label>
                            <Input
                                tag="textarea"
                                type="textarea"
                                required
                                id="desc"
                                name="desc"
                                placeholder="Tell us in a nutshell what your company is about."
                                className="bg-beige-light text-sm"
                                defaultValue={company?.desc}
                                rows={5}
                                maxLength={300}
                            />
                        </div>
                        {filters.map((filter) => {
                            if(filter.companyType && filter.companyType !== company.type && filter.companyType !== 'standard') return null; // Remove filter if not in the company type
                            return <div className="col-span-3 gap-x-5 gap-y-1 2xl:col-span-2" key={`filter_${filter.id}`}>
                                <label htmlFor={`filter_${filter.id}`} className="text-base md:whitespace-nowrap">
                                    {filter.name}*
                                </label>
                                {filter.type === "select" && (
                                    <Input
                                        tag="select"
                                        required
                                        id={`filter_${filter.id}`}
                                        name={`filter_${filter.id}`}
                                        className="bg-beige-light !px-4 !py-1"
                                        value={company?.filters?.[filter.id]}
                                        onChange={(e) => setCompany((p) => ({ ...p, filters: { ...p.filters, [filter.id]: e.target.value } }))}
                                    >
                                        <option value="" disable className="text-gray-400">
                                            {filter.multiselect && "Multi-"}Select field
                                        </option>
                                        {filter.choices.map((choice, idx) => (
                                            <option value={idx} key={`${filter.id}_${choice}`}>
                                                {choice}
                                            </option>
                                        ))}
                                    </Input>
                                )}
                                {filter.type === "input" && (
                                    <Input
                                        required
                                        id={`filter_${filter.id}`}
                                        pattern={patterns[filter.type_input] && patterns[filter.type_input].pattern}
                                        onKeyPress={(e) => {
                                            if (!patterns[filter.type_input]?.regex) return;
                                            if (!patterns[filter.type_input]?.regex.test(e.key)) e.preventDefault();
                                        }}
                                        name={`filter_${filter.id}`}
                                        placeholder="Input area"
                                        className="bg-beige-light !px-4 !py-1"
                                        defaultValue={company?.filters?.[filter.id]}
                                    />
                                )}
                            </div>
                        })}
                    </div>
                </div>
                <div className="sm:mx-16">
                    <h4 className="text-2xl">Documents</h4>
                    <div className="flex flex-col gap-1">
                        <label htmlFor="company" className="text-lg font-semibold">
                            Upload your pitch deck here
                        </label>
                        <Dropzone
                            placeholder="Drag and drop your pitch or"
                            handleFile={handlePitch}
                            setError={setError}
                            name="pitch"
                            accept={{
                                "application/pdf": [],
                            }}
                        />
                    </div>
                    {company.pitch && (
                        <div className="mt-2 flex flex-col gap-2 lg:flex-row">
                            <input type="hidden" name="pitch_delete" value={company.pitch_delete ? true : ""} />
                            <div className="flex-1 border-[1px] border-solid border-black px-[33px] py-[13px] text-lg leading-7">
                                <span className={`wrap-anywhere ${company.pitch_delete && "line-through"}`}>{company.pitch?.name}</span> <sup className="text-red-400">{company.pitch_delete ? "(delete)" : company.pitch_new && "(old)"}</sup>
                            </div>
                            <div className="flex flex-col gap-2 md:flex-row">
                                <Button
                                    type="button"
                                    loading={download}
                                    disabled={download || company.pitch.owner.path !== `users/${userOutlet.id}`}
                                    className={`flex !w-36 items-center justify-center ${company.pitch.owner.path !== `users/${userOutlet.id}` ? "bg-grey-400" : "bg-blue"} text-white`}
                                    onClick={() => downloadPitch()}
                                >
                                    Download
                                </Button>
                                <Button
                                    type="button"
                                    className={`!w-28 ${company.pitch.owner.path !== `users/${userOutlet.id}` ? "bg-grey-400" : "bg-red-400"} px-0 text-white`}
                                    onClick={() => {
                                        setCompany((e) => ({ ...e, pitch_delete: !e.pitch_delete }));
                                    }}
                                    disabled={company.pitch_new || company.pitch.owner.path !== `users/${userOutlet.id}`}
                                >
                                    {!company.pitch_delete ? "Delete" : "Undo"}
                                </Button>
                            </div>
                        </div>
                    )}
                    {company.pitch_new && (
                        <div className="mt-2 flex flex-col gap-2 lg:flex-row">
                            <div className="flex-1 border-[1px] border-solid border-black px-[33px] py-[13px] text-lg leading-7">
                                {company.pitch_new?.name} <sup className="text-green-400">(new)</sup>
                            </div>
                            <div className="flex gap-2">
                                <Button
                                    type="button"
                                    className="!w-36 bg-blue text-white"
                                    onClick={() => {
                                        const url = URL.createObjectURL(company.pitch_new);
                                        const a = document.createElement("a");
                                        a.href = url;
                                        a.download = company.pitch_new.name;
                                        a.click();
                                        URL.revokeObjectURL(url);
                                    }}
                                >
                                    Download
                                </Button>
                                <Button
                                    type="button"
                                    className="!w-28 bg-red-400 px-0 text-white"
                                    onClick={() => {
                                        setCompany((e) => ({ ...e, pitch_new: undefined }));
                                        document.forms["company"]["pitch"].value = null;
                                    }}
                                >
                                    Delete
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="sm:mx-16">
                    <Button className={`flex items-center justify-center ${success ? "bg-green-400" : "bg-blue"} text-white`} loading={submit} disabled={submit}>
                        {success ? "Info successfully saved !" : "Save"}
                    </Button>
                </div>
            </Form>
            <Toast message={error} color="red-400" disabled={!error} finish={() => setError(false)} />
            <Toast message={success} color="green-400" disabled={!success} finish={() => setSuccess(false)} />
        </div>
    );
};

export default Company;
