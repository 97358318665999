import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { doc, getDoc, getDocs, setDoc, deleteDoc, collectionGroup, query, where, updateDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase";
// import { getAuth } from "firebase/auth";

// Third party
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Pagination, Hits, Configure } from 'react-instantsearch';

// Components
import { SelectSimple, InputSimple, FdSearchBox } from "../components/Inputs.jsx";
import { Button, IconButton } from "../components/Buttons.jsx";

// icons
import { IoChevronBackOutline } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import { IoMdLink } from "react-icons/io";
import { FaEyeSlash } from "react-icons/fa6";
import { FaEye } from "react-icons/fa6";

export const User = () => {
    const { userId } = useParams();
    const { user: currentUser  } = useOutletContext();
    const navigate = useNavigate();
    const [user, setUser] = useState(false);
    const [userEvents, setUserEvents] = useState([]);
    const [init, setInit] = useState(true);
    const [saved, setSaved] = useState(false);
    const [company, setCompany] = useState(false);
    const saveTimer = useRef(false);
    // const auth = getAuth();
    // const currentUserUid = auth.currentUser.uid;

    const deleteUser = async (e) => {
        e.preventDefault();
        if(window.confirm("Are you sure you want to delete this user?")) {
            const docRef = doc(db, "users", userId);
            await deleteDoc(docRef);
            navigate(-1);
        }
    };

    const handleHideUserEvent = async (eventId) => {
        var r = window.confirm("Are you sure you want to hide this user from this event?");
        if(r === false) return;
        const docRef = doc(db, "events", eventId, "users", user.email);
        await updateDoc(docRef, {
            hidden: true
        });

        setUserEvents(userEvents.map((event) => {
            if(event.id === eventId) {
                event.hidden = true;
            }
            return event;
        }));
    };

    const handleShowUserEvent = async (eventId) => {
        var r = window.confirm("Are you sure you want to show this user from this event?");
        if(r === false) return;
        const docRef = doc(db, "events", eventId, "users", user.email);
        await updateDoc(docRef, {
            hidden: false
        });

        setUserEvents(userEvents.map((event) => {
            if(event.id === eventId) {
                event.hidden = false;
            }
            return event;
        }));
    };

    const handleRemoveUserEvent = async (eventId) => {
        var r = window.confirm("Are you sure you want to remove this user from this event?");
        if(r === false) return;
        const docRef = doc(db, "events", eventId, "users", user.email);
        await deleteDoc(docRef);

        setUserEvents([]);
    };
    
    useEffect(() => {
        // Get user from firestore
        const docRef = doc(db, "users", userId);
        getDoc(docRef).then(async (docRef) => {
            if (docRef.exists()) {
                console.log("Document data:", docRef.data());
                let userData = docRef.data();
                userData.id = docRef.id;

                // Get sub doc company
                if(userData.company) {
                    const companyDoc = await getDoc(userData.company);
                    if (companyDoc.exists()) {
                        setCompany({...companyDoc.data(), id: companyDoc.id})
                    } else {
                        console.log("No such document!");
                    }
                }

                setUser(userData);
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }, [userId]);

    useEffect(() => {
        if(init === false) {
            if(saveTimer.current) clearTimeout(saveTimer.current);
            saveTimer.current = setTimeout(() => {
                console.log("Update user", user);
                const docRef = doc(db, "users", userId);
                setDoc(docRef, user);
                setSaved(true);
                setTimeout(() => {
                    setSaved(false);
                }, 1000);
            }, 1000);
        } else if(user && user.id) {
            // Get user events regarding sub collection users in events
            const eventRef = query(collectionGroup(db, "users"), where("email", "==", user.email));
            getDocs(eventRef).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    if (doc.id !== user.email) return;
                    const userEventSubscription = doc.data();
                    getDoc(doc.ref.parent.parent).then((eventDoc) => {
                        let event = eventDoc.data();
                        event.id = eventDoc.id;
                        event.userType = userEventSubscription.type;
                        event.hidden = userEventSubscription.hidden;
                        setUserEvents([...userEvents, event]);
                    });
                });
            });
            setInit(false);
        }
        // eslint-disable-next-line
    }, [user]);

    return <div className="container">
        <div className="flex items-center justify-between w-100">
            <div className="flex items-center text-5xl ">
                <IoChevronBackOutline className="cursor-pointer" onClick={() => navigate(-1)} /> {user && user.firstname} {user && user.lastname}
                <div className={`transition-all duration-1000 ${saved ? 'opacity-100' : 'opacity-0'}`}><FaCheckCircle className="text-green-700 ml-2" /></div>
            </div>
            <div style={{ textAlign: 'right' }}>
                {currentUser && user && currentUser.id !== user.id && <><a href="/" onClick={deleteUser} className="text-red-700"><small>Delete user</small></a><br /></>}
                <small className="text-xs">id: {userId}</small><br />
                <small className="text-xs">email: {user?.email}</small>
            </div>
        </div>
        <div className="grid grid-cols-4 gap-2">
            <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Admin role</label>
                <SelectSimple disabled={currentUser && user && currentUser.id === user.id ? true : false} name="adminRole" id="adminRole" label="Admin role" value={user && user.adminRole ? user.adminRole : 'user'} onChange={(e) => {
                        setUser({ ...user, [e.target.name]: e.target.value });
                    }} options={[
                    { value: 'none', label: 'None' },
                    { value: 'superadmin', label: 'Super admin' }
                ]} />
            </div>
        </div>
        <div className="grid grid-cols-4 gap-2 mt-5">
            <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">First name</label>
                <InputSimple name="firstname" id="firstname" label="First name" value={user && user.firstname ? user.firstname : ''} onChange={(e) => {
                    setUser({ ...user, [e.target.name]: e.target.value });
                }} />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Last name</label>
                <InputSimple name="lastname" id="lastname" label="Last name" value={user && user.lastname ? user.lastname : ''} onChange={(e) => {
                    setUser({ ...user, [e.target.name]: e.target.value });
                }} />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Phone</label>
                <InputSimple name="phone" id="phone" label="Phone" value={user && user.phone ? user.phone : ''} onChange={(e) => {
                    setUser({ ...user, [e.target.name]: e.target.value });
                }} />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Position</label>
                <InputSimple name="position" id="position" label="Position" value={user && user.position ? user.position : ''} onChange={(e) => {
                    setUser({ ...user, [e.target.name]: e.target.value });
                }} />
            </div>
        </div>
        <div className="grid grid-cols-3 mt-5 gap-2">
            {company ? <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Linked company</label>
                <div className="flex items-center">
                    <IoIosCloseCircle onClick={() => {
                        setUser({ ...user, company: null });
                    }} className="text-lg mr-1 cursor-pointer" />
                    <span>{company && <b>{company.name}</b>}</span>
                    <IoMdLink onClick={() => {
                        navigate(`/companies/${company.id}`);
                    }} className="text-lg ml-1 cursor-pointer" />
                </div>
            </div> : <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Linked company</label>
                <b>No company linked</b>
            </div>}
        </div>
        {userEvents.length > 0 && <div className="grid grid-cols-3 mt-5 gap-2">
            <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Events</label>
                <div className="grid grid-cols-1 gap-2">
                    {userEvents.map((event) => {
                        return <div key={event.id} className="flex items-center">
                            <b className="mr-1">{event.name}</b> ({event.userType}) 
                            {event.hidden ? <IconButton onClick={() => handleShowUserEvent(event.id)} className="ml-1"><FaEyeSlash /></IconButton> : <IconButton onClick={() => handleHideUserEvent(event.id)} className="ml-1"><FaEye /></IconButton>}
                            <IconButton className="ml-1" onClick={() => handleRemoveUserEvent(event.id)}><IoIosCloseCircle /></IconButton>
                        </div>;
                    })}
                </div>
            </div>
        </div>}
    </div>;
};

export const Users = () => {
    // const { user: userOutlet } = useOutletContext();
    const [displayAdmins, setDisplayAdmins] = useState(false);
    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState(false);
    const [algoliaFilters, setAlgoliaFilters] = useState('');
    const navigate = useNavigate();

    const searchClient = algoliasearch(window.fdVariables.algolia.appId, window.fdVariables.algolia.apiKey);

    useEffect(() => {
        // Get all events
        getDocs(collection(db, "events")).then((querySnapshot) => {
            let tmpEvents = [];
            querySnapshot.forEach((doc) => {
                tmpEvents.push({ ...doc.data(), id: doc.id });
            });
            setEvents(tmpEvents);
        });
        // eslint-disable-next-line
    }, []);

    const defineAlgoliaFilters = async () => {
        let algoliaFiltersString = '';
        if(displayAdmins) algoliaFiltersString = 'adminRole:superadmin';
        if(event) {
            // Get all users from this event
            const querySnapshot = await getDocs(collection(db, "events", event, "users"))
            let tmpUsers = [];
            querySnapshot.forEach((doc) => {
                tmpUsers.push(doc.data().email);
            });

            if(algoliaFiltersString !== '') algoliaFiltersString += ' AND ';
            // format : (email:email1 OR email:email2 OR email:email3)
            algoliaFiltersString += '(' + tmpUsers.map((email) => {
                return 'email:' + email;
            }).join(' OR ') + ')';
        }
        setAlgoliaFilters(algoliaFiltersString);
    }

    useEffect(() => {
        defineAlgoliaFilters();
        // eslint-disable-next-line
    }, [displayAdmins, event]);

    return <div>
        <h1 className="text-5xl">User management</h1>
        <InstantSearch searchClient={searchClient} indexName="users">
            <Configure filters={algoliaFilters} />
            <div className="flex mt-5 mb-5">
                <div className="flex-1 flex p-2 items-center">
                    <FdSearchBox className="w-full" />
                </div>
                <div className="flex-1 flex p-2 items-center">
                    <select className="flex-1 w-32 p-2 border border-gray-300 rounded-md" style={{ border: '1px solid #e4e4e4'}} onChange={(e) => {
                        setEvent(e.target.value === '' ? false : e.target.value);
                    }}>
                        <option value="">All events</option>
                        {events.map((event) => {
                            return <option key={event.id} value={event.id}>{event.name}</option>;
                        })}
                    </select>
                </div>
                <div className="flex-1 flex p-2 items-center">
                    <input type="checkbox" id="admins" name="admins" className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded" onChange={() => setDisplayAdmins(!displayAdmins)} value={displayAdmins} />
                    <label htmlFor="admins" className="ml-2 block text-sm text-gray-900 dark:text-gray-200">Display admins only</label>
                </div>
            </div>
            <Hits hitComponent={({hit}) => {
                return <div key={hit.id} className="grid grid-cols-4 gap-2 mt-2">
                    <div className="col-span-3">
                        <div><b>{hit.firstname} {hit.lastname}</b></div>
                        <small><i>{hit.phone}</i></small>
                    </div>
                    <div>
                        <Button onClick={() => navigate(`/users/${hit.objectID}`)}>Détails</Button>
                    </div>
                </div>
            
        }} />
            <Pagination />
        </InstantSearch>
        <style>
            {`
                .ais-Pagination-list {
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;
                    column-gap: 10px;
                }
            `}
        </style>
    </div>;
};

// export default Users;
