import React from "react";
import { Outlet, NavLink, redirect, useLoaderData, useOutletContext } from "react-router-dom";
import { collectionGroup, where, query, getDocs, getDoc, doc, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../../firebase";

import { ReactComponent as LogoIllustration } from "../../assets/logo-drawer.svg";
import { ReactComponent as BackCircleIllustration } from "../../assets/backCircle.svg";
import { ReactComponent as BackGreenIllustration } from "../../assets/backGreen.svg";
import { ReactComponent as PolygonIcon } from "../../assets/polygon.svg";

export const loader = async ({ request }) => {
    const auth = getAuth();

    await auth.authStateReady();
    if (!auth.currentUser) return redirect("/login");

    const docRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);

    const url = new URL(request.url);
    let isNew = url.searchParams.get("new");
    const create = url.searchParams.get("create");

    if (!docSnap.exists() || !docSnap.data().email) {
        if (url.pathname === "/profile" && !isNew) return redirect("/profile?new=true");
        if (url.pathname === "/company" && !create) return redirect("/profile?new=true");
        if (url.pathname !== "/profile" && url.pathname !== "/company") return redirect("/profile?new=true");
    } else if (isNew) {
        isNew = false;
        url.searchParams.delete("new");
        window.history.replaceState({}, "", url.toString());
    }

    let company;
    const user = { ...docSnap.data(), id: docSnap.id };
    if (user?.company) company = (await getDoc(user.company)).data();

    return { user: user || { isNew }, company };
};

const Drawer = () => {
    const [user, setUser] = React.useState({});
    const [events, setEvents] = React.useState(null);
    const { user: userLoad, company } = useLoaderData();

    React.useEffect(() => {
        setUser(userLoad);
        get_events();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLoad]);

    const linkStyle = ({ isActive, isPending }) => {
        if (isActive) return "bg-blue-light font-bold";
        if (isPending) return "pending font-semibold";
        return "font-semibold hover:bg-blue-light";
    };

    const subLinkStyle = ({ isActive, isPending }) => {
        if (isActive) return "bg-blue-light";
        if (isPending) return "pending";
        return "hover:bg-blue-light";
    };

    const nextEvent = (event) => (event.endDate.toMillis() > Date.now() ? "!font-bold" : "");

    const get_events = async () => {
        if (userLoad.email === undefined) return;
        const _events = [];

        const eventRef = query(collectionGroup(db, "users"), where("email", "==", userLoad.email));
        const querySnapshot = await getDocs(eventRef);
        for (const doc of querySnapshot.docs) {
            if (doc.id !== userLoad.email || doc.data().hidden) continue;
            const event = await getDoc(doc.ref.parent.parent);
            _events.push({ ...event.data(), id: event.id, user: doc.data(), iDominant: event.data().dominant === doc.data().type, users: [] });

            if (_events.at(-1).iDominant) {
                const usersRef = await getDocs(collection(db, `events/${event.id}/users`));
                usersRef.forEach((doc) => _events.at(-1).users.push(doc.data()));
            }
        }
        setEvents(_events);
    };

    return (
        <div className="flex h-full overflow-hidden">
            <div className="drawer group fixed z-50 h-full w-14 overflow-x-hidden bg-blue text-white transition-all duration-300 hover:w-[317px] xl:w-[317px]">
                <section className="mt-8 hidden flex-col items-center gap-4 group-hover:flex xl:flex">
                    <LogoIllustration />
                    <div className="flex flex-col gap-2 text-center">
                        <h1 className="text-2xl font-bold">{user?.firstname ? `${user.firstname} ${user.lastname}` : "New users"}</h1>
                        <h2 className="text-lg font-bold">{company?.name}</h2>
                    </div>
                </section>
                <hr className="my-6 hidden border-white group-hover:block xl:block" />
                <section className="hidden flex-col group-hover:flex xl:flex">
                    <NavLink to="/" className={(props) => `text-lg ${linkStyle(props)} truncate px-8 py-2.5`}>
                        Home
                    </NavLink>
                    <NavLink to="/profile" className={(props) => `text-lg ${linkStyle(props)} truncate px-8 py-2.5`}>
                        Edit my profile
                    </NavLink>
                    {user?.company && (
                        <NavLink to="/company" className={(props) => `text-lg ${linkStyle(props)} truncate px-8 py-2.5`}>
                            Edit my company
                        </NavLink>
                    )}
                    <NavLink to="/logout" className={(props) => `text-lg ${linkStyle(props)} truncate px-8 py-2.5`}>
                        Logout
                    </NavLink>
                </section>
                <hr className="my-6 hidden border-white group-hover:block xl:block" />
                <section className="hidden flex-col group-hover:flex xl:flex">
                    <div className="flex items-center px-8">
                        <h1 className="text-2xl font-bold">Your matchmaking events</h1>
                    </div>
                    <div className="mt-6 flex flex-col">
                        {events?.map((event) => {
                            if (!event.iDominant)
                                return (
                                    <NavLink to={`/events/${event.id}/schedule`} className={(props) => `text-lg ${linkStyle(props)} line-clamp-2 max-h-[63px] w-full break-all px-8 py-2.5 ${nextEvent(event)}`} key={`nav-${event.id}`}>
                                        {event.name}
                                    </NavLink>
                                );

                            return (
                                <details className="group" key={`nav-${event.id}`}>
                                    <summary className="flex cursor-pointer list-none items-baseline gap-2 px-8 py-2.5 font-medium">
                                        <span className="-rotate-90 transition group-open:rotate-0">
                                            <PolygonIcon />
                                        </span>
                                        <span className="line-clamp-2 max-h-[63px] break-all text-lg">{event.name}</span>
                                    </summary>
                                    <div className="group-open:animate-fadeIn">
                                        {event.wishEndDate.toMillis() > Date.now() && (
                                            <>
                                                <NavLink to={`/events/${event.id}/attendees`} className={(props) => `font-inter text-base font-normal ${subLinkStyle(props)} line-clamp-2 max-h-[63px] w-full break-all py-2.5 pl-16 pr-2.5`}>
                                                    All the attendees
                                                </NavLink>
                                                <NavLink to={`/events/${event.id}/wishlist`} className={(props) => `font-inter text-base font-normal ${subLinkStyle(props)} line-clamp-2 max-h-[63px] w-full break-all py-2.5 pl-16 pr-2.5`}>
                                                    My wishlist
                                                </NavLink>
                                            </>
                                        )}
                                        <NavLink to={`/events/${event.id}/schedule`} className={(props) => `font-inter text-base font-normal ${subLinkStyle(props)} line-clamp-2 max-h-[63px] w-full break-all py-2.5 pl-16 pr-2.5`}>
                                            My schedule
                                        </NavLink>
                                    </div>
                                </details>
                            );
                        })}
                    </div>
                </section>
                <div className="bg-blue-400 absolute top-0 h-full w-full transition-all delay-100 duration-300 group-hover:w-0">
                    <div className="relative left-1/2 top-1/2 block h-11 w-2 -translate-x-1/2 -translate-y-1/2 transform rounded-xl bg-white group-hover:hidden xl:hidden"></div>
                </div>
            </div>
            <div className="drop fixed top-0 z-30 h-full w-0 bg-black bg-opacity-15 transition-all delay-100"></div>
            <div className="fixed right-0 -z-10 ml-10 h-full pl-20 xl:pl-[357px]">
                <BackGreenIllustration className="relative right-0 h-full" />
            </div>
            <div className="fixed right-0 -z-10 ml-10 mt-28 w-full pl-20 pr-10 xl:pl-[357px]">
                <BackCircleIllustration className="relative w-full" />
            </div>
            <div className="z-1 ml-14 flex-1 overflow-y-auto transition-all duration-300 xl:ml-[317px]">
                <div className="m-10">
                    <Outlet context={{ user, events, company }} />
                </div>
            </div>
        </div>
    );
};

export function useRoot() {
    return useOutletContext();
}

export default Drawer;
