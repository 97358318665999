import React from "react";
import { Back, Spinner } from "../../components";
import { useOutletContext } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const Schedule = () => {
    const { event, user } = useOutletContext();
    const [meeting, setMeeting] = React.useState({});
    const [who] = React.useState({ me: event.iDominant ? "dominant" : "dominated", other: event.iDominant ? "dominated" : "dominant" });

    React.useEffect(() => {
        if (!event) return;
        const getMeeting = async () => {
            const queryRef = query(collection(db, "events/" + event.id + "/meetings"), where(`${who.me}.id`, "==", user.id), where("status", "==", "validated"));
            const snaps = await getDocs(queryRef);
            snaps.forEach((doc) => {
                setMeeting((e) => ({ ...e, [doc.data().slot]: { id: doc.id, ...doc.data() } }));
            });
        };
        getMeeting();

        // eslint-disable-next-line
    }, [event, user]);

    if (!event) return <Spinner />;

    const haveMeeting = !!Object.keys(meeting).length || event?.wishEndDate.toMillis() < Date.now();
    return (
        <div className="flex flex-col gap-8">
            <div className="flex items-center gap-6">
                <Back to="/" />
                <h2 className="text-5xl text-blue">My schedule</h2>
            </div>
            <div className="sm:mx-16">
                <h4 className="text-2xl">{haveMeeting ? "Your schedule for the day!" : "Your schedule for the day is coming soon!"}</h4>
                {!haveMeeting && <p className="text-sm text-black">It’s not ready yet, but come back in a few days before the event to see your schedule for the day.</p>}
                {haveMeeting && <p className="text-sm text-black">Keep a close eye on your email — we'll keep you posted if there's an update.</p>}
            </div>
            <div className="flex flex-col gap-8 sm:mx-16">
                {!haveMeeting && <p className="text-sm text-black">Until then, be sure to set up or update your profile and your company’s.</p>}
                {haveMeeting &&
                    Object.entries(event?.slots || {})
                        .sort((a, b) => a[1].begin.toMillis() - b[1].begin.toMillis())
                        .map(([id, slot]) => (
                            <div key={id} className="flex items-center gap-8">
                                <p className="text-lg font-semibold">{new Date(slot.begin.toMillis()).toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: false })}</p>
                                <p className={`text-lg font-semibold ${meeting[id] ? "text-black" : "text-grey-500"}`}>
                                    {meeting[id] ? `Appointment with ${meeting[id][who.other].firstname} ${meeting[id][who.other].lastname} @${meeting[id][who.other].company}` : "No appointment"}
                                </p>
                            </div>
                        ))}
            </div>
            <div className="sm:mx-16">
                <h4 className="text-2xl">Meet us here</h4>
                <iframe
                    title={event.location}
                    width="492"
                    height="300"
                    style={{ border: 0 }}
                    loading="lazy"
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBBIC_Hghtq7cX2uxEn2hcpSlbFccvYDhY&q=${event.place} ${event.address}`}
                ></iframe>
            </div>
            <div className="sm:mx-16">
                <h4 className="text-2xl">Can’t attend the event? </h4>
                <p className="text-sm text-black">
                    Make sure to notify as soon as possible the team at{" "}
                    <a className="text-blue" href={`mailto:startup@francedigitale.org`}>
                        startup@francedigitale.org
                    </a>
                    .
                </p>
            </div>
        </div>
    );
};

export default Schedule;
