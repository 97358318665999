import React from "react";
import { Outlet, NavLink, redirect, useLoaderData } from "react-router-dom";

import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";

export const loader = async ({ request }) => {
    const auth = getAuth();

    await auth.authStateReady();
    if (!auth.currentUser) return redirect("/login");

    const docRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);

    const url = new URL(request.url);
    const isNew = url.searchParams.get("new");
    if (!isNew && !docSnap.exists()) return redirect("/profile?new=true");
    return { user: docSnap?.data() || { isNew } };
};

const logout = async () => {
    const auth = getAuth();
    await auth.signOut();
    document.location.href = "/";
}

const AdminDrawer = ({ children }) => {
    const [user, setUser] = React.useState({});
    const { user: userLoad } = useLoaderData();

    React.useEffect(() => {
        setUser(userLoad);
        // if(!userLoad.adminRole || userLoad.adminRole !== 'superadmin') redirect("/login");
    }, [userLoad]);

    if(!userLoad.adminRole || userLoad.adminRole !== 'superadmin') return <div>Unauthorized</div>;

    return <div className="container">
        {/* Define layout with left sidebar navigation, open on desktop, reduced on mobile */}
        <div className="flex h-full min-h-screen">
            <div className="w-64 bg-gray-800" style={{
                backgroundColor: "#2d3748",
            }}>
                <div className="flex items-center justify-center h-16 bg-gray-900 text-white">
                    <span className="text-2xl font-bold">Admin</span>
                </div>
                <nav className="mt-10">
                    <NavLink to="/" className="flex items-center text-white py-2 pl-4 bg-gray-900">
                        Dashboard
                    </NavLink>
                    <NavLink to="/events" className="flex items-center text-white py-2 pl-4">
                        Events
                    </NavLink>
                    <NavLink to="/users" className="flex items-center text-white py-2 pl-4">
                        Users
                    </NavLink>
                    <NavLink to="/companies" className="flex items-center text-white py-2 pl-4">
                        Companies
                    </NavLink>
                    <NavLink to="/filters" className="flex items-center text-white py-2 pl-4">
                        Filters
                    </NavLink>
                    {/* Logout link */}
                    <div className="flex items-center text-white py-2 pl-4 cursor-pointer" onClick={logout}>
                        Logout
                    </div>
                </nav>
            </div>
            <div className="flex-1 p-10">
                <Outlet context={{ user }} />
            </div>
        </div>
    </div>;

}
export default AdminDrawer;