import React, { useEffect } from "react";
import { useMenu, useInstantSearch, useRefinementList } from "react-instantsearch";

import Input from "./Input";

export function MenuSelect({ _filter, ...props }) {
    const { items, refine } = useMenu(props);
    const { value: selectedValue } = items.find((item) => item.isRefined) || {
        value: "",
    };

    return (
        <Input
            tag="select"
            className="!w-auto bg-beige-light !px-2 !py-1"
            value={selectedValue}
            onChange={(event) => {
                refine(event.target.value);
            }}
            {...props}
        >
            <option value="">{items.multiselect && "Multi-"}Select field</option>
            {items
                .sort((a, b) => a.value - b.value)
                .map((item) => (
                    <option value={item.value} key={`${items.id}_${item.value}`}>
                        {_filter ? _filter.choices[item.value] : item.value} ({item.count})
                    </option>
                ))}
        </Input>
    );
}

export function HiddenRevertSelect({ not, ...props }) {
    const { setIndexUiState, indexUiState } = useInstantSearch();
    const { items } = useRefinementList(props);

    useEffect(() => {
        if (indexUiState?.refinementList?.[props.attribute]?.length) return;
        setIndexUiState((prevUiState) => ({
            ...prevUiState,
            refinementList: {
                ...prevUiState.refinementList,
                [props.attribute]: items.filter((itm) => itm.value !== not).map((itm) => itm.value),
            },
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexUiState]);

    return null;
}
