import React, { useState, useEffect } from "react";
import { Outlet, useLoaderData, useNavigate, useParams } from "react-router-dom";

import algoliasearch from "algoliasearch/lite";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

import { useRoot } from "../Layout/Drawer";
import { Spinner } from "../../components";

export const loader = async () => {
    const colRef = collection(db, "filters");
    const querySnapshot = await getDocs(colRef);
    const filters = [];
    querySnapshot.forEach((doc) => filters.push({ ...doc.data(), id: doc.id }));
    return { filters };
};

export const action = async () => {};

export const EventOutlet = () => {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const { events, user } = useRoot();
    const { filters } = useLoaderData();
    const [event, setEvent] = useState();
    const searchClient = algoliasearch(window.fdVariables.algolia.appId, window.fdVariables.algolia.apiKey);

    useEffect(() => {
        if (!events) return;
        const _event = events.find((event) => event.id === eventId);
        if (!_event) return navigate("/");
        setEvent(_event);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events]);

    if (!event) return <Spinner />;

    return <Outlet context={{ event, user, searchClient, filters }} />;
};

export default EventOutlet;
