import { Link, useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <section className="flex h-full items-center p-16 dark:bg-gray-50 dark:text-gray-800">
            <div className="container mx-auto my-8 flex flex-col items-center justify-center px-5">
                <div className="max-w-md text-center">
                    <h2 className="mb-8 text-9xl font-extrabold dark:text-gray-400">
                        <span className="sr-only">Error</span>
                        {error.status || "Error"}
                    </h2>
                    <p className="text-2xl font-semibold md:text-3xl">{error.statusText || error.message}</p>
                    <p className="mb-8 mt-4 dark:text-gray-600">you can go back to the homepage</p>
                    <Link to={`/`} className="rounded px-8 py-3 font-semibold dark:bg-violet-600 dark:text-gray-50">
                        Back to homepage
                    </Link>
                </div>
            </div>
        </section>
    );
}
