import React from "react";

import { ReactComponent as BackIllustration } from "../assets/arrow-right.svg";
import { NavLink } from "react-router-dom";

const Back = (props) => {
    return (
        <NavLink className="flex h-[50px] w-[50px] -rotate-2 transform items-center justify-center bg-blue" {...props}>
            <BackIllustration className="rotate-2 transform" />
        </NavLink>
    );
};

export default Back;
