import React, { useEffect } from "react";
import { useNavigate, useOutletContext, useLocation, NavLink } from "react-router-dom";

import { getDoc, doc, updateDoc, setDoc, onSnapshot } from "firebase/firestore";
import { ref, getBlob } from "firebase/storage";
import { db, storage } from "../../firebase";

import { InstantSearch, Pagination, Hits, SortBy, Configure } from "react-instantsearch";

import { Back, Button, MenuSelect, Toast, Spinner } from "../../components";
import { ReactComponent as HeartIllustration } from "../../assets/heart.svg";
import { ReactComponent as DownloadIllustration } from "../../assets/download.svg";
import { ReactComponent as CloseIllustration } from "../../assets/close.svg";
import { ReactComponent as UnderIllustration } from "../../assets/underline.svg";

const Attendees = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { event, searchClient, filters, user: userOutlet } = useOutletContext();

    const [page, setPage] = React.useState(location.pathname.split("/").pop() === "wishlist" ? 1 : 0);
    const [view, setView] = React.useState(null);
    // eslint-disable-next-line
    const [isLimitDate, setIsLimitDate] = React.useState(false);
    const [wishlist, setWishlist] = React.useState([]);
    const [download, setDownload] = React.useState(false);
    const [error, setError] = React.useState(null);

    useEffect(() => {
        setPage(location.pathname.split("/").pop() === "wishlist" ? 1 : 0);
    }, [location]);

    useEffect(() => {
        if (!event || !userOutlet) return;
        if (!event.iDominant || event.wishEndDate.toMillis() < Date.now()) return navigate(`/events/${event.id}/schedule`);
        // setIsLimitDate(event.wishEndDate.toMillis() < Date.now());
        const docRef = doc(db, `events/${event.id}/wishlist/${userOutlet.id}`);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (!doc.exists()) return;
            setWishlist(doc.data().users);
        });

        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event]);

    useEffect(() => {
        if (!view) return;
        if (view.contact) return;
        const getContact = async () => {
            const docRef = doc(db, `users/${view.events[event.id].users[0].user}`);
            const contact = await getDoc(docRef);
            setView((prev) => ({ ...prev, contact: contact.data() }));
        };
        getContact();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view]);

    const addRemoveWishlist = async (userCompany) => {
        if (isLimitDate) return setError("You can't add or remove companies from your wishlist after the matchmaking limit date");
        const userRef = doc(db, `users/${userCompany.users[0].user}`);
        const docRef = doc(db, `events/${event.id}/wishlist/${userOutlet.id}`);
        const wishlist = await getDoc(docRef);

        if (!wishlist.exists()) {
            await setDoc(docRef, { users: [userRef] });
            return;
        }

        const users = wishlist.data().users;
        if (users.find((c) => c.path === userRef.path)) {
            await updateDoc(docRef, { users: users.filter((c) => c.path !== userRef.path) });
            return;
        }

        await updateDoc(docRef, { users: [...users, userRef] });
    };

    const downloadPitch = async () => {
        if (event.endDate.toMillis() < Date.now()) return setError("The event has ended, you can't download the pitch anymore");
        setDownload(true);
        try {
            if (!view.pitch || !view.pitch.ref) return;
            const blob = await getBlob(ref(storage, view.pitch.ref));
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = view.pitch.name;
            a.click();
        } catch (error) {
            console.error("Failed to download pitch:", error);
            setError("Failed to download pitch");
        }
        setDownload(false);
    };

    const Company = () => (
        <>
            <div className="fixed inset-0 z-50 bg-[#4F4D4D] bg-opacity-50" />
            <div className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="relative m-8 flex w-full max-w-[646px] flex-col gap-4 bg-beige-light px-10 py-7 shadow-[6px_9px_0px_0px_#FFA14B40]" key={`company_${view.id}`}>
                    <CloseIllustration className="absolute right-3 top-4 cursor-pointer" onClick={() => setView(null)} />
                    {view.logo && <img src={view.logo.url} alt={view.name} className="h-52 object-contain" />}
                    <div className="flex flex-col gap-3">
                        <h4 className="self-center text-2xl text-blue">{view.name}</h4>
                        <a href={view.website} target="_blank" rel="noreferrer" className="self-center text-lg text-black">
                            Visit the website
                        </a>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col flex-wrap gap-x-3 gap-y-1 sm:flex-row">
                                <h4 className="text-base leading-4 text-black">Contact</h4>
                                <p className="break-word text-base font-normal leading-4 text-black">
                                    {view.contact?.firstname} {view.contact?.lastname}
                                </p>
                            </div>
                            <div>
                                <h4 className="text-base leading-4 text-black">Description</h4>
                                <p className="break-word text-base font-normal leading-4 text-black">{view.desc}</p>
                            </div>
                            <div className="flex flex-row flex-wrap gap-4 sm:justify-around">
                                {filters
                                    .filter((f) => event.filters.includes(f.id))
                                    .map((filter) => (
                                        <div key={`${filter.id}_${view.id}`} className="align-center flex flex-col flex-wrap gap-x-3 gap-y-1 sm:flex-row">
                                            <h4 className="text-base leading-4 text-black">{filter.name}</h4>
                                            <p className="break-word text-base font-normal leading-4 text-black">{filter.choices?.[view.filters?.[filter.id]] ?? view.filters?.[filter.id] ?? "Not specified"}</p>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center gap-4">
                        {view.pitch?.ref && (
                            <Button type="button" className="flex items-center justify-center gap-3 bg-green-400 !px-2 !py-3 leading-5" loading={download} disabled={download} onClick={downloadPitch}>
                                <DownloadIllustration />
                                Download company’s pitch
                            </Button>
                        )}
                        <Button
                            className={`flex items-center justify-center gap-3 ${isLimitDate ? "bg-grey-400" : "bg-blue"} !px-2 !py-3 leading-5`}
                            type="button"
                            onClick={() => addRemoveWishlist(view.events[event.id])}
                            disabled={isLimitDate}
                        >
                            {wishlist.find((c) => c.path === `users/${view.events?.[event.id]?.users?.[0].user}`) ? (
                                "Remove from wishlist"
                            ) : (
                                <>
                                    <HeartIllustration />
                                    Add to wishlist
                                </>
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );

    const queryConstructor = (conf) => {
        console.log(
            conf,
            `events.${event.id}.users.type:'${event.dominated ?? "Startup"}' ` +
                event.users
                    .filter((u) => u.hidden)
                    .map((u) => `AND NOT events.${event.id}.users.email:'${u.email}'`)
                    .join(" "),
        );
        switch (conf) {
            case 0:
                return (
                    `events.${event.id}.users.type:'${event.dominated ?? "Startup"}' ` +
                    event.users
                        .filter((u) => u.hidden)
                        .map((u) => `AND NOT events.${event.id}.users.email:'${u.email}'`)
                        .join(" ")
                );
            case 1:
                return wishlist.map((c) => `events.${event.id}.users.user:${c.path.split("/").at(-1)}`).join(" OR ") || "none";
            default:
                return "";
        }
    };

    if (!event) return <Spinner />;
    return (
        <div className="flex flex-col gap-8">
            <div className="flex gap-6">
                <Back to="/" />
                <div>
                    <h2 className="text-5xl text-blue">{event.name}</h2>
                    <p className="text-4xl text-black">Meet the startups</p>
                </div>
            </div>
            <div className="flex flex-col justify-center gap-8 sm:mx-16 md:flex-row">
                <NavLink className={`flex cursor-pointer flex-col items-center ${!!page && "px-[25.5px]"}`} to={`/events/${event.id}/attendees`}>
                    <h4 className={`text-2xl ${!page ? "text-black" : "text-grey-400"}`}>All the attendees</h4>
                    {!page && <UnderIllustration />}
                </NavLink>
                <NavLink className={`flex cursor-pointer flex-col items-center ${!page && "px-[25.5px]"}`} to={`/events/${event.id}/wishlist`}>
                    <h4 className={`text-2xl ${page ? "text-black" : "text-grey-400"} flex items-center gap-3`}>
                        My Wishlist <HeartIllustration />
                    </h4>
                    {!!page && <UnderIllustration />}
                </NavLink>
            </div>
            <div className="flex flex-col gap-8 sm:mx-16">
                <InstantSearch searchClient={searchClient} indexName="companies">
                    <Configure filters={queryConstructor(page)} />
                    <div className="gap-x-5 gap-y-1 md:mr-28 lg:flex lg:items-center">
                        <label htmlFor="sort_by_algo" className="text-base">
                            Sort by
                        </label>
                        <SortBy items={[{ value: "companies", label: "companies" }]} />
                    </div>
                    <div className="flex flex-wrap gap-4">
                        {filters
                            .filter((f) => event.filters.includes(f.id))
                            .map((filter) => (
                                <div className="gap-x-5 gap-y-1 md:mr-28 lg:flex lg:items-center" key={`${filter.id}_algo`}>
                                    <label htmlFor={filter.id} className="text-base">
                                        {filter.name}
                                    </label>
                                    <MenuSelect attribute={`filters.${filter.id}`} _filter={filter.type === "select" && filter} />
                                </div>
                            ))}
                    </div>
                    <Hits
                        classNames={{ list: "flex flex-wrap gap-9 flex-rows" }}
                        hitComponent={({ hit }) => {
                            console.log();
                            return (
                                <div className="flex w-full max-w-[420px] flex-col gap-4 bg-beige-light px-4 py-3 shadow-[6px_9px_0px_0px_#FFA14B40]" key={`company_${hit.id}`}>
                                    {hit.logo && <img src={hit.logo.url} alt={hit.name} className="h-32 object-contain" />}
                                    <div className="flex flex-col gap-3">
                                        <h4 className={`self-center text-2xl text-blue ${!hit.logo ? "mt-17" : ""}`}>{hit.name}</h4>
                                        <a href={hit.website} target="_blank" rel="noreferrer" className={`self-center text-lg text-black ${!hit.logo ? "mb-17" : ""}`}>
                                            Visit the website
                                        </a>
                                        <div className="flex flex-col gap-4">
                                            <div>
                                                <h4 className="text-base leading-4 text-black">Description</h4>
                                                <p className="break-word text-sm text-black">{hit.desc}</p>
                                            </div>
                                            {filters
                                                .filter((f) => event.filters.includes(f.id))
                                                .slice(0, 3)
                                                .map((filter) => (
                                                    <div key={`${filter.id}_${hit.id}`}>
                                                        <h4 className="text-base leading-4 text-black">{filter.name}</h4>
                                                        <p className="break-word text-sm text-black">{filter.choices?.[hit.filters?.[filter.id]] ?? hit.filters?.[filter.id] ?? "Not specified"}</p>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap justify-center gap-1">
                                        <Button className="!w-48 -rotate-2 bg-green-400 !px-2 !py-3 leading-5" onClick={() => setView(hit)} type="button">
                                            <p className="rotate-1">View company’s pitch</p>
                                        </Button>
                                        <Button className={`!w-48 -rotate-2 ${isLimitDate ? "bg-grey-400" : "bg-blue"} !px-2 !py-3 leading-5`} type="button" onClick={() => addRemoveWishlist(hit.events[event.id])} disabled={isLimitDate}>
                                            <p className="rotate-1">{wishlist.find((c) => c.path === `users/${hit.events?.[event.id]?.users?.[0].user}`) ? "Remove from wishlist" : "Add to wishlist"}</p>
                                        </Button>
                                    </div>
                                </div>
                            );
                        }}
                    />
                    <Pagination classNames={{ list: "flex flex-row justify-center gap-4" }} />
                </InstantSearch>
            </div>

            {view && <Company />}
            <Toast message={error} color="red-400" disabled={!error} finish={() => setError(false)} />
        </div>
    );
};

export default Attendees;
