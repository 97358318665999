import React from 'react';
import { SearchBox } from 'react-instantsearch';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

export const CheckBox = ({ name, id, label, checked, onChange, className = "", ...props } = {}) => {
    return <div className="flex items-center">
        <input type="checkbox" name={name} id={id} checked={checked} onChange={onChange} className={"h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded " + className} {...props} />
        <label htmlFor={id} className="ml-2 block text-sm text-gray-900 dark:text-gray-200">{label}</label>
    </div>;
}

export const InputSimple = ({ type = "text", name, id, placeholder, className = "", value, required, defaultValue, ...props } = {}) => {
    return <input 
        type={type} 
        name={name} 
        id={id} 
        defaultValue={defaultValue} 
        placeholder={placeholder} 
        className={"block w-full h-12 rounded-md py-1.5 pl-5 pt-3 pb-3 pr-5 text-stone-900 ring-inset rounded-md border border-grey-700 ring-stone-300 focus:ring-stone-900 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6 !outline-none " + className} 
        required={required} 
        value={value ? value : ''}
        {...props}
    />;
}

export const SelectSimple = ({ name, id, className = "", required, value, defaultValue, options, ...props }) => {
    return <select
        name={name}
        id={id}
        className={"block w-full h-12 rounded-md py-1.5 pl-5 pt-3 pb-3 pr-5 text-stone-900 ring-inset rounded-md border border-grey-700 ring-stone-300 focus:ring-stone-900 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6 !outline-none " + className}
        required={required}
        defaultValue={defaultValue}
        value={value ? value : ''}
        {...props}
    >
        {options.map((option) => {
            return <option key={option.value} value={option.value}>{option.label}</option>;
        })}
    </select>;
}

export const TextArea = ({ name, id, placeholder, className = "", value, required, defaultValue, ...props } = {}) => {
    return <textarea
        name={name}
        id={id}
        defaultValue={defaultValue}
        placeholder={placeholder}
        className={"block w-full h-32 rounded-md py-1.5 pl-5 pt-3 pb-3 pr-5 text-stone-900 ring-inset rounded-md border border-grey-700 ring-stone-300 focus:ring-stone-900 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6 !outline-none " + className}
        required={required}
        value={value ? value : ''}
        {...props}
    />;
}

export const FormInput = ({ placeholder, actionText, onSubmit, required, defaultValue } = {}) => {

    return <form onSubmit={onSubmit}>
        <label for="default-search" className="mb-2 text-sm font-medium text-grey sr-only dark:text-grey">Search</label>
        <div className="relative">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg className="w-5 h-5 text-grey-500 dark:text-grey-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </div>
            <input type="search" value={defaultValue ? defaultValue : ''} required={required} id="default-search" className="block p-4 pl-10 w-full text-sm text-black bg-grey rounded-lg border border-grey focus:ring-grey focus:border-grey dark:bg-grey dark:border-grey dark:placeholder-black dark:text-black dark:focus:ring-grey dark:focus:border-grey focus:outlined-none" placeholder={placeholder} />
            <button type="submit" style={{ backgroundColor: 'blue' }} className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{actionText}</button>
        </div>
    </form>;
}

export const DateTimePicker = ({ name, id, label, value, onChange, className = "", ...props } = {}) => {
    try {
        value = value && value.toDate ? value.toDate() : value;
    } catch(e) {
        console.log('Error converting date', e);
    }
    
    return <Datetime
        value={value && value !== '' ? value : ''}
        onChange={onChange}
        inputProps={{ placeholder: label, className: "block w-full h-12 rounded-md py-1.5 pl-5 pt-3 pb-3 pr-5 text-stone-900 ring-inset rounded-md border border-grey-700 ring-stone-300 focus:ring-stone-900 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6 !outline-none " + className }}
        {...props}
    />;
}

export const Input = ({ tag: Tag = "input", type = "text", className = "", ...props } = {}) => {
    return <Tag type={type} className={"font-degular w-full min-w-56 px-[33px] py-[13px] text-base font-medium leading-7 text-black !outline-none " + className} {...props} />;
};

export const FdSearchBox = (props) => {
    return <>
        {/* Add css rules */}
        <style>
            {`
            .ais-SearchBox {
                display: flex;
                flex-direction: row;
                align-items: center;
                border: 1px solid #e4e4e4;
                border-radius: 10px;
                padding: 0 10px;
                position: relative;
                width: 100%;
            }
            .ais-SearchBox-input {
                border: none;
                outline: none;
                padding: 10px;
                flex: 1;
                width: 100%;
            }
            .ais-SearchBox-form {
                width: 100%;
            }
            .ais-SearchBox-submit {
                background-color: #ccc;
                color: white;
                border: none;
                border-radius: 10px;
                padding: 10px;
                margin-left: 10px;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
            .ais-SearchBox-reset {
                position: absolute;
                right: 50px;
                top: 50%;
                transform: translateY(-50%);
            }
            `}
        </style>
        <SearchBox />
    </>;
}