import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { collection, doc, getDocs, getDoc, setDoc, query, where, addDoc, deleteDoc } from "firebase/firestore";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Papa from 'papaparse';
import uniqid from 'uniqid';
import { db } from "../../../firebase";

import { RoundIconButton } from "../components/Buttons.jsx";
import { InputSimple, SelectSimple, TextArea, DateTimePicker } from "../components/Inputs.jsx";
import Dropzone from "../../../components/Dropzone.jsx";

import { MdAddAlarm } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { TiDeleteOutline } from "react-icons/ti";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { HiOutlineTrash } from "react-icons/hi";
import { MdOutlineEditCalendar } from "react-icons/md";

export const Event = () => {
    const { eventId } = useParams();
    const navigate = useNavigate();
    const [event, setEvent] = useState(false);
    const [init, setInit] = useState(true);
    const [saved, setSaved] = useState(false);
    const [filters, setFilters] = useState([]);
    const [searchEventUsers, setSearchEventUsers] = useState("");
    const [eventUsers, setEventUsers] = useState([]); // [ { email: "", type: "", date: "" } ]
    const updateTimer = useRef(false);

    const getFilters = () => {
        // Get filters from firestore
        const filtersRef = collection(db, "filters");
        getDocs(filtersRef).then((querySnapshot) => {
            let filterList = [];
            querySnapshot.forEach((doc) => {
                let filter = doc.data();
                filter.id = doc.id;
                filterList.push(filter);
            });
            setFilters(filterList);
        }
        ).catch((error) => {
            console.log("Error getting documents: ", error);
        });
    }

    useEffect(() => {
        // Get user from firestore
        const docRef = doc(db, "events", eventId);
        getDoc(docRef).then((doc) => {
            if (doc.exists()) {
                let eventData = doc.data();
                eventData.id = doc.id;
                
                // console.log(eventData.slots && eventData.slots, eventData.slots && Object.keys(eventData.slots));
                if(eventData.slots && Object.keys(eventData.slots).length > 0) {
                    let newSlots = {};
                    // Reorder eventData.slots (format { slotId: { begin: Date, end: Date, creationDate: Date, index: Number } }) by index value, preserve original keys
                    Object.keys(eventData.slots).sort((a, b) => {
                        return eventData.slots[a].index - eventData.slots[b].index;
                    }).forEach((slotId) => {
                        newSlots[slotId] = eventData.slots[slotId];
                    });
                    eventData.slots = newSlots;
                }
                setEvent(eventData);
                getFilters();
                // console.log("Document data:", doc.data());
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }, [eventId]);

    useEffect(() => {
        if(init === false) {
            if(updateTimer.current) clearTimeout(updateTimer.current);
            updateTimer.current = setTimeout(() => {
                const docRef = doc(db, "events", eventId);
                setDoc(docRef, event);
                setSaved(true);
                setTimeout(() => {
                    setSaved(false);
                }, 1000);
            }, 1000);
        } else if(event && event.id) {
            setInit(false);
        }
        // eslint-disable-next-line
    }, [event]);

    const handleUsers = (files) => {
        for(const file of files) {
            const reader = new FileReader();
            let countUsers = 0;
            reader.onload = (e) => {
                const text = e.target.result;
                const results = Papa.parse(text, { header: true, skipEmptyLines: true });
                results.data.forEach((result) => {
                    setDoc(doc(db, "events", eventId, "users", result.email), {
                        email: result.email,
                        type: result.type,
                        date: new Date(),
                    });
                    countUsers++;
                });
            };
            reader.onloadend = () => {
                alert(`${countUsers} Users added / updated successfully`);
            }
            reader.readAsText(file);
        };
    };

    const handleSearchUsers = (searchTerm) => {
        // If searchEventUsers is email search
        if(searchTerm.length >= 3) {
            const usersRef = collection(db, 'events', eventId, 'users');
            // search email begins with searchEventUsers
            const q = query(usersRef, where('email', '>=', searchTerm), where('email', '<=', searchTerm + '\uf8ff'));
            getDocs(q).then((querySnapshot) => {
                let userList = [];
                querySnapshot.forEach((docRef) => {
                    let userItem = docRef.data();
                    userItem.id = docRef.id;
                    userList.push(userItem);
                });
                setEventUsers(userList);
            });
        }
    }

    const handleDeleteUser = (userId) => {
        console.log("Delete user", userId);
        let confirm = window.confirm("Are you sure you want to delete this user?");
        if(confirm === false) return;

        const userRef = doc(db, 'events', eventId, 'users', userId);
        deleteDoc(userRef).then(() => {
            console.log("Document successfully deleted!");
            handleSearchUsers(searchEventUsers);
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    }

    const handleAddSlot = () => {
        console.log("Add slot");
        const slots = event.slots ? event.slots : {};
        slots[uniqid()] = {
            begin: new Date(),
            end: new Date(),
            creationDate: new Date(),
            index: Object.keys(slots).length,
        };
        setEvent({ ...event, slots });
    }

    const handleDeleteSlot = (slotId) => {
        console.log("Delete slot", slotId);
        let confirm = window.confirm("Are you sure you want to delete this slot?");
        if(confirm === false) return;

        const slots = event.slots;
        delete slots[slotId];

        // Update index
        let index = 0;
        for(const slotId in slots) {
            slots[slotId].index = index;
            index++;
        }

        setEvent({ ...event, slots });
    }

    const handleReorderSlots = (result) => {
        console.log("Reorder slots", result);
        if (!result.destination) return;
        
        const slots = event.slots;
        const items = Object.entries(slots);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let index = 0;
        let newSlots = {};
        items.forEach(([slotId, slot]) => {
            slot.index = index;
            newSlots[slotId] = slot;
            index++;
        });

        setEvent({ ...event, slots: newSlots });

    }

    const handleChangeFilters = (filterId) => {
        let eventFilters = [];
        if(!event.filters) {
            eventFilters = filters.map((filter) => filter.id);
        } else {
            eventFilters = event.filters;
        }
        if(eventFilters.includes(filterId)) {
            eventFilters = eventFilters.filter((f) => f !== filterId);
        } else {
            eventFilters.push(filterId);
        }
        setEvent({ ...event, filters: eventFilters });
    }

    return <div className="pb-4">
        <div className="flex items-center text-5xl">
            <h1 className="text-5xl">{event.name}</h1>
            <div className={`transition-all duration-1000 ${saved ? 'opacity-100' : 'opacity-0'}`}><FaCheckCircle className="text-green-700 ml-2" /></div>
        </div>
        <div className="mt-5 grid grid-cols-3 gap-2">
            <div>
                <label>Event Name</label>
                <InputSimple label="Event name" value={event.name} onChange={(e) => setEvent({ ...event, name: e.target.value })} />
            </div>
            <div>
                <label>Event Dominant</label>
                <SelectSimple label="Dominant" name="dominant" value={event && event.dominant ? event.dominant : 'Venture Capital'} options={[
                    { value: "standard", label: "Standard User" },
                    { value: "Agency / consulting", label: "Agency / consulting" },
                    { value: "Corporate", label: "Corporate" },
                    { value: "Ecosystem", label: "Ecosystem" },
                    { value: "Policy Maker", label: "Policy Maker" },
                    { value: "Startup", label: "Startup" },
                    { value: "Venture Capital", label: "Venture Capital" },
                ]} onChange={(e) => {
                    console.log("Change dominant");
                    setEvent({ ...event, dominant: e.target.value });
                }} />
                <small>Event dominant can access startup list & select them to wishlist</small>
            </div>
            <div>
                <label>Event Dominanted</label>
                <SelectSimple label="Dominanted" name="dominanted" value={event && event.dominanted ? event.dominanted : 'Startup'} options={[
                    { value: "standard", label: "Standard User" },
                    { value: "Agency / consulting", label: "Agency / consulting" },
                    { value: "Corporate", label: "Corporate" },
                    { value: "Ecosystem", label: "Ecosystem" },
                    { value: "Policy Maker", label: "Policy Maker" },
                    { value: "Startup", label: "Startup" },
                    { value: "Venture Capital", label: "Venture Capital" },
                ]} onChange={(e) => {
                    console.log("Change dominant");
                    setEvent({ ...event, dominanted: e.target.value });
                }} />
                <small>Event dominanted are selected by dominant & can just see schedule</small>
            </div>
        </div>
        <div>
            <label>Event Description</label>
            <TextArea label="Description" value={event.description} onChange={(e) => setEvent({ ...event, description: e.target.value })} />
        </div>
        <div className="mt-5 grid grid-cols-2 gap-2">
            <div>
                <label>Place name</label>
                <InputSimple label="Place name" value={event.place} onChange={(e) => setEvent({ ...event, place: e.target.value })} />
            </div>
            <div>
                <label>Place address</label>
                <InputSimple label="Place address" value={event.address} onChange={(e) => setEvent({ ...event, address: e.target.value })} />
            </div>
        </div>
        <div className="mt-5 grid grid-cols-2 gap-2">
            <div>
                <label>Event Start Date</label>
                <DateTimePicker label="Start Date" value={event.startDate} onChange={(date) => {
                    if(typeof date === 'object') {
                        setEvent({ ...event, startDate: date.toDate() })
                    }
                }} dateFormat={'YYYY-MM-DD'} timeFormat={"HH:mm"} />
            </div>
            <div>
                <label>Event End Date</label>
                <DateTimePicker label="End Date" value={event.endDate} onChange={(date) => {
                    if(typeof date === 'object') {
                        setEvent({ ...event, endDate: date.toDate() })
                    }
                }} dateFormat={'YYYY-MM-DD'} timeFormat={"HH:mm"} />
            </div>
            <div>
                <label>Wishes End Date</label>
                <DateTimePicker label="Wish Date" value={event.wishEndDate} onChange={(date) => {
                    if(typeof date === 'object') {
                        setEvent({ ...event, wishEndDate: date.toDate() })
                    }
                }} dateFormat={'YYYY-MM-DD'} timeFormat={"HH:mm"} />
                <small>End date to make wishes. Calendar will be displayed for everybody at this time</small>
            </div>
        </div>
        <div className="mt-5">
            <h2 className="text-2xl">Filters</h2>
            {/* By default, if not event.filters, all filters are available. Either, select only ids in event.filters. Filters can be selected with checkboxes */}
            <div className="">
                {filters.map((filter) => {
                    return <div key={filter.id}>
                        <input className="mr-2" type="checkbox" checked={event.filters ? event.filters.includes(filter.id) : true} onChange={() => handleChangeFilters(filter.id)} />
                        <label>{filter.name}</label>
                    </div>;
                })}
            </div>
        </div>
        <div className="mt-5">
            <div className="flex items-center">
                <h2 className="text-2xl">Event Users</h2>
                <a href="https://storage.googleapis.com/public-fd/samples/import.csv" target="_blank" rel="noreferrer" className="ml-2">(Download sample csv)</a>
            </div>
            <Dropzone
                placeholder="Drag and drop your csv file here or click to browse"
                handleFile={handleUsers}
                name="users"
                accept={{
                    "text/csv": [],
                }}
                style={{
                    maxHeight: "100px",
                }}
            />
            <InputSimple placeholder="Search users" className="mt-2" label="Search users" value={searchEventUsers} onChange={(e) => {
                setSearchEventUsers(e.target.value)
                handleSearchUsers(e.target.value);
            }} />
            <div className="mt-2">
                {eventUsers.map((user) => {
                    return <div key={user.id} className="bg-white shadow-md p-5 rounded-lg mt-5">
                        <div className="flex items-center justify-between">
                            <div><span className="text-lg">{user.email}</span> <small className="ml-2">({user.type})</small></div>
                            <div>
                                <RoundIconButton onClick={() => handleDeleteUser(user.id)}>
                                    <TiDeleteOutline className="text-lg" />
                                </RoundIconButton>
                            </div>
                        </div>
                    </div>;
                })}
            </div>
        </div>
        <div className="mt-5">
            <div className="flex items-center">
                <h2 className="text-2xl mr-2">
                    Meeting Slots
                </h2>
                <RoundIconButton onClick={handleAddSlot}>
                    <MdOutlineAddCircleOutline size={24} />
                </RoundIconButton>
                <RoundIconButton onClick={() => navigate(`/events/${eventId}/meetings`)}>
                    <MdOutlineEditCalendar size={24} />
                </RoundIconButton>
            </div>
            <div>
                <DragDropContext onDragEnd={handleReorderSlots}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {Object.keys(event.slots ? event.slots : {}).map((slotId, index) => (
                                    <Draggable key={slotId} draggableId={slotId} index={index}>
                                        {(provided) => (
                                            <div
                                                className="bg-white shadow-md p-5 rounded-lg mt-5"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div className="grid grid-cols-3 gap-2">
                                                    <div className="col-span-3 flex items-center">
                                                        <RoundIconButton onClick={() => handleDeleteSlot(slotId)}>
                                                            <HiOutlineTrash size={24} color="red" />
                                                        </RoundIconButton>
                                                        <span className="mr-1">Slot id : {slotId}</span>
                                                    </div>
                                                    <div>
                                                        <label>Slot name</label>
                                                        <InputSimple label="Name" value={event.slots[slotId].name} onChange={(e) => {
                                                            let slots = event.slots;
                                                            slots[slotId].name = e.target.value;
                                                            setEvent({ ...event, slots });
                                                        }} />
                                                    </div>
                                                    <div>
                                                        <label>Slot Begin</label>
                                                        <DateTimePicker label="Begin" value={event.slots[slotId].begin} onChange={(date) => {
                                                            let slots = event.slots;
                                                            // Check if date chain is moment object
                                                            if(typeof date === 'object') {
                                                                console.log(date)
                                                                slots[slotId].begin = date.toDate();
                                                                setEvent({ ...event, slots });
                                                            }
                                                        }} dateFormat={'YYYY-MM-DD'} timeFormat={"HH:mm"} />
                                                    </div>
                                                    <div>
                                                        <label>Slot End</label>
                                                        <DateTimePicker label="End" value={event.slots[slotId].end} onChange={(date) => {
                                                            let slots = event.slots;
                                                            if(typeof date === 'object') {
                                                                slots[slotId].end = date.toDate();
                                                                setEvent({ ...event, slots });
                                                            }
                                                        }} dateFormat={'YYYY-MM-DD'} timeFormat={"HH:mm"} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    </div>;
}

export const Events = () => {
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);

    useEffect(() => {
        // Get all events from firestore
        const q = query(collection(db, "events"));
        let eventList = [];
        getDocs(q).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                let event = doc.data();
                event.id = doc.id;
                eventList.push(event);
                console.log(doc.id, " => ", doc.data());
            });
            setEvents(eventList);
        }).catch((error) => {
            console.log("Error getting documents: ", error);
        });
    }, []);

    const createEvent = () => {
        const eventName = window.prompt("Event name");
        if(eventName) {
            addDoc(collection(db, "events"), {
                name: eventName,
                creationDate: new Date(),
            }).then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                navigate(`/events/${docRef.id}`);
            }).catch((error) => {
                console.error("Error adding document: ", error);
            });
        }
    }

    return <div>
        <div>
            <h1 className="text-5xl">Events</h1>
            <RoundIconButton onClick={createEvent} className="fixed top-5 right-5">
                <MdAddAlarm className="text-5xl" />
            </RoundIconButton>
        </div>
        <div className="mt-5">
            {/* List events */}
            {events.map((event) => {
                return <div key={event.id} className="bg-white shadow-md p-5 rounded-lg mt-5 cursor-pointer" onClick={() => navigate(`/events/${event.id}`)}>
                    <div className="flex items-center justify-between">
                        <span className="text-2xl">{event.name}</span>
                        <div>
                            <RoundIconButton onClick={() => navigate(`/events/${event.id}`)}>
                                <MdAddAlarm className="text-2xl" />
                            </RoundIconButton>
                        </div>
                    </div>
                </div>;
            })}
        </div>
    </div>;
}