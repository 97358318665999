import React, { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Button } from "../components";

const Home = () => {
    const { user, events } = useOutletContext();

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <div>
            <div className="gap-6">
                <h2 className="text-5xl text-blue">Hi {user.firstname} 👋</h2>
                <h2 className="text-4xl text-black">Your next matchmaking is coming up!</h2>
            </div>
            <div className="my-14 flex flex-col gap-16 sm:mx-16">
                {events
                    ?.filter((event) => event.endDate.toMillis() > Date.now())
                    .sort((a, b) => a.startDate.toMillis() - b.startDate.toMillis())
                    .map((event) => (
                        <div className="relative" key={event.id}>
                            <div className="absolute left-2 top-3 -z-10 h-full w-full rounded-lg bg-blue shadow-[11px_7px_20px_0px_#00000024]" />
                            <div className="flex w-full flex-col items-center gap-y-4 rounded-lg border-[1px] border-grey-400 bg-white px-7 py-6 ">
                                <h3 className="text-4xl text-black">{event.name}</h3>
                                <div className="flex max-w-[350px] flex-col gap-4">
                                    <div>
                                        <p className="text-sm text-black">
                                            <span className="mr-2 font-bold">Date</span>
                                            {new Date(event.startDate.toMillis()).toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric" })}
                                        </p>
                                        <p className="text-sm text-black">
                                            <span className="mr-2 font-bold">Location</span>
                                            {event.location}
                                        </p>
                                        <p className="text-sm text-black">
                                            <span className="mr-2 font-bold">Time</span>
                                            {event.endDate.toMillis() - event.startDate.toMillis() < 24 * 60 * 60 * 1000 ? (
                                                <span>
                                                    <span className="mr-2 font-bold">Time</span>
                                                    {new Date(event.startDate.toMillis()).toLocaleTimeString("en-US", { hour: "numeric" })} - {new Date(event.endDate.toMillis()).toLocaleTimeString("en-US", { hour: "numeric" })}
                                                </span>
                                            ) : (
                                                <span>
                                                    <span className="mr-2 font-bold">Time</span>
                                                    {new Date(event.startDate.toMillis()).toLocaleTimeString("en-US", { hour: "numeric", weekday: "long" })} -{" "}
                                                    {new Date(event.endDate.toMillis()).toLocaleDateString("en-US", { hour: "numeric", weekday: "long" })}
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                    <Link to={`/company`}>
                                        <Button className={`flex items-center justify-center ${event.iDominant ? "bg-blue" : "bg-red-400"} text-white`}>{event.iDominant ? "See my company information" : "Add my company information"}</Button>
                                    </Link>
                                    {event.iDominant && event.wishEndDate.toMillis() > Date.now() && (
                                        <Link to={`/events/${event.id}/attendees`}>
                                            <Button className={`flex items-center justify-center bg-blue text-white`}>Select startups</Button>
                                        </Link>
                                    )}
                                    <Link to={`/events/${event.id}/schedule`}>
                                        <Button className={`flex items-center justify-center bg-blue text-white`}>See my agenda</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <div className="gap-6">
                <h2 className="text-5xl text-blue">Past events</h2>
            </div>
            <div className="my-14 flex flex-col gap-16 sm:mx-16">
                {events
                    ?.filter((event) => event.endDate.toMillis() < Date.now())
                    .sort((a, b) => a.startDate.toMillis() - b.startDate.toMillis())
                    .map((event) => (
                        <div className="relative" key={event.id}>
                            <div className="absolute left-2 top-3 -z-10 h-full w-full rounded-lg bg-blue shadow-[11px_7px_20px_0px_#00000024]" />
                            <div className="flex w-full flex-col items-center gap-y-4 rounded-lg border-[1px] border-grey-400 bg-white px-7 py-6 ">
                                <h3 className="text-4xl text-black">{event.name}</h3>
                                <div className="flex max-w-[350px] flex-col gap-4">
                                    <div>
                                        <p className="text-sm text-black">
                                            <span className="mr-2 font-bold">Date</span>
                                            {new Date(event.startDate.toMillis()).toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric" })}
                                        </p>
                                        <p className="text-sm text-black">
                                            <span className="mr-2 font-bold">Location</span>
                                            {event.location}
                                        </p>
                                        <p className="text-sm text-black">
                                            <span className="mr-2 font-bold">Time</span>
                                            {event.endDate.toMillis() - event.startDate.toMillis() < 24 * 60 * 60 * 1000 ? (
                                                <span>
                                                    <span className="mr-2 font-bold">Time</span>
                                                    {new Date(event.startDate.toMillis()).toLocaleTimeString("en-US", { hour: "numeric" })} - {new Date(event.endDate.toMillis()).toLocaleTimeString("en-US", { hour: "numeric" })}
                                                </span>
                                            ) : (
                                                <span>
                                                    <span className="mr-2 font-bold">Time</span>
                                                    {new Date(event.startDate.toMillis()).toLocaleTimeString("en-US", { hour: "numeric", weekday: "long" })} -{" "}
                                                    {new Date(event.endDate.toMillis()).toLocaleDateString("en-US", { hour: "numeric", weekday: "long" })}
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                    <Link to={`/events/${event.id}/schedule`}>
                                        <Button className={`flex !w-auto items-center justify-center bg-grey-700 text-white`}>See my agenda</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default Home;
