import React from "react";
import Spinner from "./Spinner";

const Button = ({ children, onClick, type = "submit", loading, className = "", disabled, ...props } = {}) => {
    return (
        <button type={type} onClick={onClick} className={`w-full px-[33px] py-[13px] text-lg font-bold leading-7 text-white ${!disabled ? "hover:bg-opacity-90 active:bg-opacity-80" : ""} ${className}`} disabled={disabled} {...props}>
            {loading && <Spinner />}
            {!loading && children}
        </button>
    );
};

export default Button;
