import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { collection, doc, getDoc, getDocs, setDoc, addDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../../firebase";

import { Button } from "../components/Buttons.jsx";
import { SelectSimple, InputSimple, CheckBox } from "../components/Inputs.jsx";

import { IoChevronBackOutline } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";

export const Filter = () => {
    const { filterId } = useParams();
    const navigate = useNavigate();
    const [filter, setFilter] = useState({});
    const [init, setInit] = useState(true);
    const [saved, setSaved] = useState(false);

    useEffect(() => {
        // Get user from firestore
        const docRef = doc(db, "filters", filterId);
        getDoc(docRef).then((doc) => {
            if (doc.exists()) {
                // console.log("Document data:", doc.data());
                let filterData = doc.data();
                filterData.id = doc.id;
                setFilter(filterData);
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }, [filterId]);

    useEffect(() => {
        if(init === false) {
            console.log("Update filter", filter);
            const docRef = doc(db, "filters", filterId);
            setDoc(docRef, filter);
            setSaved(true);
            setTimeout(() => {
                setSaved(false);
            }, 1000);
        } else if(filter && filter.id) {
            setInit(false);
        }
        // eslint-disable-next-line
    }, [filter]);

    return <div>
        <div className="text-5xl flex items-center justify-between w-100">
            <div className="flex items-center"><IoChevronBackOutline className="cursor-pointer" onClick={() => navigate('/filters')} /> {filter.name}</div>
            <div className={`transition-all duration-1000 ${saved ? 'opacity-100' : 'opacity-0'}`}><FaCheckCircle className="text-green-700 ml-2" /></div>
        </div>
        <div className="grid grid-cols-3 gap-2 mt-2">
            <div>
                <InputSimple name="name" id="name" placeholder="Name" onChange={(e) => {
                    setFilter({ ...filter, [e.target.name]: e.target.value });
                }} value={filter.name} />
            </div>
            <div>
                <SelectSimple name="type" id="type" options={[
                    { value: "input", label: "Input" },
                    { value: "select", label: "Select" },
                ]} onChange={(e) => {
                    setFilter({ ...filter, [e.target.name]: e.target.value });
                }} value={filter.type} />
            </div>
            <div>
                <SelectSimple name="companyType" id="company-type" options={[
                    { value: "standard", label: "All Users" },
                    { value: "Agency / consulting", label: "Agency / consulting" },
                    { value: "Corporate", label: "Corporate" },
                    { value: "Ecosystem", label: "Ecosystem" },
                    { value: "Policy Maker", label: "Policy Maker" },
                    { value: "Startup", label: "Startup" },
                    { value: "Venture Capital", label: "Venture Capital" },
                ]} onChange={(e) => {
                    setFilter({ ...filter, [e.target.name]: e.target.value });
                }} value={filter.companyType} />
            </div>
        </div>
        {filter.type === "select" && <div className="grid grid-cols-3 gap-2 mt-2">
            <div></div>
            <div>
                <CheckBox name="multiselect" id="multiselect" label="MultiSelect" checked={filter.multiselect} onChange={(e) => {
                    setFilter({ ...filter, [e.target.name]: e.target.checked });
                }} />
            </div>
        </div>}
        {filter.type === "input" && <div className="mt-2">
            {/* Select input type, number or text */}
            <SelectSimple name="type_input" id="type_input" options={[
                { value: "text", label: "Text" },
                { value: "number", label: "Number" },
            ]} onChange={(e) => {
                setFilter({ ...filter, [e.target.name]: e.target.value });
            }} value={filter.type_input} />
        </div>}
        {filter.type === "select" && <div className="mt-2">
            <div className="text-lg">Options</div>
            <div className="grid grid-cols-8 gap-2 mt-2 items-center">
                {filter.choices && filter.choices.map((choice, index) => {
                    return <React.Fragment key={index}>
                        <div className="col-span-7" key={index}>
                            <InputSimple name="choices" id="choices" placeholder="Name" onChange={(e) => {
                                filter.choices[index] = e.target.value;
                                setFilter({ ...filter });
                            }} value={choice} />
                        </div>
                        <div>
                            <Button className={'w-full bg-red-700'} onClick={() => {
                                filter.choices.splice(index, 1);
                                setFilter({ ...filter });
                            }}>Delete</Button>
                        </div>
                    </React.Fragment>;
                })}
            </div>
            <Button className={'bg-green-700 mt-2'} onClick={() => {
                if(!filter.choices) filter.choices = [];
                filter.choices.push("");
                setFilter({ ...filter });
            }}>Add</Button>
        </div>}
    </div>;
};

export const Filters = () => {
    const navigate = useNavigate();
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        const fetchFilters = async () => {
            const filtersCollection = collection(db, "filters");
            const filtersSnapshot = await getDocs(filtersCollection);
            const filtersData = filtersSnapshot.docs.map((doc) => {
                return { id: doc.id, ...doc.data() };
            });
            setFilters(filtersData);
        };

        fetchFilters();
    }, []);

    return <div>
        <h1 className="text-5xl">Filters management</h1>
        {filters.map((filter) => {
            return <div key={filter.id} className="grid grid-cols-4 gap-2 mt-2">
                <div className="col-span-3">
                    <div className="text-lg">{filter.name}</div>
                    <small className="text-sm">{filter.type}</small>
                </div>
                <div className="grid grid-cols-2 gap-2">
                    <Button onClick={() => navigate(`/filters/${filter.id}`)}>Détails</Button>
                    <Button className={'bg-red-700'} onClick={() => {
                        const r = window.confirm("Do you really want to delete this filter?");
                        if(r) {
                            deleteDoc(doc(db, "filters", filter.id));
                            setFilters(filters.filter((f) => f.id !== filter.id));
                        }
                    }}>Delete</Button>
                </div>
            </div>;
        })}
        <Button className={'bg-green-700 mt-2'} onClick={() => {
            const newFilter = {
                name: "New filter",
                type: "input",
            };
            addDoc(collection(db, "filters"), newFilter).then((docRef) => {
                navigate(`/filters/${docRef.id}`);
            });
        }}>Add</Button>
    </div>;
};