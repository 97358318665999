import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { collection, doc, getDocs, getDoc, setDoc, query, where } from "firebase/firestore";
import { db, storage } from "../../../firebase";
import { ref, getDownloadURL } from "firebase/storage";

// Third party
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Pagination, Hits } from 'react-instantsearch';

// Components
import { FdSearchBox, SelectSimple, InputSimple, Input } from "../components/Inputs.jsx";
import { Button } from "../components/Buttons.jsx";
import { Card } from "../components/Cards.jsx";

// icons
import { IoChevronBackOutline } from "react-icons/io5";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";

export const Company = () => {
    const { companyId } = useParams();
    const navigate = useNavigate();
    const [company, setCompany] = useState(false);
    const [users, setUsers] = useState(false);
    const [init, setInit] = useState(true);
    const [saved, setSaved] = useState(false);
    const [filters, setFilters] = useState([]);
    const saveTimer = useRef(false);
    
    useEffect(() => {
        // Get user from firestore
        const docRef = doc(db, "companies", companyId);
        getDoc(docRef).then((doc) => {
            if (doc.exists()) {
                console.log("Document data:", doc.data());
                let companyData = doc.data();
                companyData.id = doc.id;
                setCompany(companyData);
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });

        // Get users linked to company
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('company', '==', docRef));
        getDocs(q).then((querySnapshot) => {
            let userList = [];
            querySnapshot.forEach((docRef) => {
                let userItem = docRef.data();
                userItem.id = docRef.id;
                userList.push(userItem);
            });
            setUsers(userList);
        });

        const filtersRef = collection(db, 'filters');
        // Get all filters
        getDocs(filtersRef).then((querySnapshot) => {
            let filterList = [];
            querySnapshot.forEach((docRef) => {
                let filterItem = docRef.data();
                filterItem.id = docRef.id;
                filterList.push(filterItem);
            });
            setFilters(filterList);
        });

        // eslint-disable-next-line
    }, [companyId]);

    useEffect(() => {
        if(init === false) {
            if(saveTimer.current) clearTimeout(saveTimer.current);
            saveTimer.current = setTimeout(() => {
                const docRef = doc(db, "companies", companyId);
                setDoc(docRef, company);
                setSaved(true);
                setTimeout(() => {
                    setSaved(false);
                }, 1000);
            }, 1000);
        } else if(company && company.id) {
            setInit(false);
        }
        // eslint-disable-next-line
    }, [company]);

    // Download pitch
    const downloadPitch = async (e) => {
        e.preventDefault();
        const url = await getDownloadURL(ref(storage, company.pitch.ref));
        window.open(url, '_blank');
    }

    const patterns = {
        number: {
            pattern: "[0-9]*",
            regex: /[0-9]/,
        },
        text: "",
    };

    return <div className="container">
        <div className="flex items-center justify-between w-100">
            <div className="flex items-center text-5xl ">
                <IoChevronBackOutline className="cursor-pointer" onClick={() => navigate(-1)} /> {company && company.name}
                <div className={`transition-all duration-1000 ${saved ? 'opacity-100' : 'opacity-0'}`}><FaCheckCircle className="text-green-700 ml-2" /></div>
            </div>
            <small className="text-xs">id: {companyId}</small>
        </div>
        <div className="grid grid-cols-2 gap-2 mt-5">
            <div className="flex items-center flex-col">
                {company && company.logo && company.logo.url ? <img src={company.logo.url} alt={company.name} className="w-40 h-40 object-cover" /> : <div className="w-64 h-64 bg-gray-200 flex items-center justify-center text-gray-500">No logo</div>}
                {company && company.website && <a 
                    href={company.website.match('https://') ? company.website : 'https://' + company.website}
                    target="_blank" 
                    className="flex items-center text-blue-600" 
                    rel="noreferrer">
                        <FaExternalLinkAlt className="mr-2" /> {company.website}
                    </a>}
            </div>
            <div>
                <SelectSimple label="Type" name="type" value={company && company.type ? company.type : ''} options={[
                    { value: "standard", label: "Standard User" },
                    { value: "Agency / consulting", label: "Agency / consulting" },
                    { value: "Corporate", label: "Corporate" },
                    { value: "Ecosystem", label: "Ecosystem" },
                    { value: "Policy Maker", label: "Policy Maker" },
                    { value: "Startup", label: "Startup" },
                    { value: "Venture Capital", label: "Venture Capital" },
                ]} onChange={(e) => {
                    console.log("Change type");
                    setCompany({ ...company, type: e.target.value });
                }} />
                <div className="text-sm mt-3">
                    <b className="text-lg">Description</b><br />
                    <InputSimple type="text" name="description" placeholder="Description" value={company && company.desc ? company.desc : ''} onChange={(e) => {
                        setCompany({ ...company, desc: e.target.value });
                    }} />
                </div>
                <div className="text-sm mt-3">
                    <b className="text-lg">Website</b><br />
                    <InputSimple type="text" name="website" placeholder="Website" value={company && company.website ? company.website : ''} onChange={(e) => {
                        setCompany({ ...company, website: e.target.value });
                    }} />
                </div>
                {company && company.pitch ? <div className="text-sm mt-3"><b className="text-lg">Pitch</b><br /><a href="/" onClick={downloadPitch}>{company.pitch.name}</a></div> : <div className="text-sm mt-3 text-gray-500"><b className="text-lg">Pitch</b><br />No pitch</div>}
            </div>
        </div>
        <hr className="mt-4 mb-4" />
        <div className="grid grid-flow-row grid-cols-1 gap-8 lg:grid-cols-6">
            {filters.map((filter) => (
                <div className="col-span-3 gap-x-5 gap-y-1 2xl:col-span-2" key={`filter_${filter.id}`}>
                    <label htmlFor={`filter_${filter.id}`} className="text-base md:whitespace-nowrap">
                        {filter.name}*
                    </label>
                    {filter.type === "select" && (
                        <Input
                            tag="select"
                            required
                            id={`filter_${filter.id}`}
                            name={`filter_${filter.id}`}
                            className="bg-beige-light !px-4 !py-1"
                            value={company?.filters?.[filter.id]}
                            onChange={(e) => setCompany((p) => ({ ...p, filters: { ...p.filters, [filter.id]: e.target.value } }))}
                        >
                            <option value="" disable className="text-gray-400">
                                {filter.multiselect && "Multi-"}Select field
                            </option>
                            {filter.choices.map((choice, idx) => (
                                <option value={idx} key={`${filter.id}_${choice}`}>
                                    {choice}
                                </option>
                            ))}
                        </Input>
                    )}
                    {filter.type === "input" && (
                        <Input
                            required
                            id={`filter_${filter.id}`}
                            pattern={patterns[filter.type_input] && patterns[filter.type_input].pattern}
                            onKeyPress={(e) => {
                                if (!patterns[filter.type_input]?.regex) return;
                                if (!patterns[filter.type_input]?.regex.test(e.key)) e.preventDefault();
                            }}
                            onChange={(e) => setCompany((p) => ({ ...p, filters: { ...p.filters, [filter.id]: e.target.value } }))}
                            name={`filter_${filter.id}`}
                            placeholder="Input area"
                            className="bg-beige-light !px-4 !py-1"
                            defaultValue={company?.filters?.[filter.id]}
                        />
                    )}
                </div>
            ))}
        </div>
        <hr className="mt-4" />
        <div className="mt-4 ">
            <span className="text-lg">Linked users</span>
            <div className="mt-2">
                {users && users.map((user) => {
                    return <Card key={user.id} className={"cursor-pointer"} bodyClassName={"grid grid-cols-3 gap-2 items-center"} onClick={() => navigate(`/users/${user.id}`)}>
                        <div className="col-span-2">
                            <div><b>{user.firstname} {user.lastname}</b></div>
                            <div>{user.phone}</div>
                        </div>
                        <div className="flex justify-end">
                            <FiUser className="text-5xl" />
                        </div>
                    </Card>;
                })}
            </div>
        </div>
    </div>;
};

export const Companies = () => {
    const navigate = useNavigate();
    const searchClient = algoliasearch(window.fdVariables.algolia.appId, window.fdVariables.algolia.apiKey);

    return <div>
        <h1 className="text-5xl">Company management</h1>
        <InstantSearch searchClient={searchClient} indexName="companies">
            <div className="flex mt-5 mb-5">
                <div className="flex-1 w-64">
                    <FdSearchBox />
                </div>
                <div className="flex-1 w-64"></div>
            </div>
            <Hits hitComponent={({hit}) => {
                return <div key={hit.id} className="grid grid-cols-4 gap-2 mt-2">
                    <div className="col-span-3">
                        <div><b>{hit.name}</b></div>
                    </div>
                    <div>
                        <Button onClick={() => navigate(`/companies/${hit.objectID}`)}>Détails</Button>
                    </div>
                </div>
            
        }} />
            <Pagination />
        </InstantSearch>
        <style>
            {`
                .ais-Pagination-list {
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;
                    column-gap: 10px;
                }
            `}
        </style>
    </div>;
};

// export default Users;
