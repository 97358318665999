import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { getDoc, getDocs, doc, addDoc, deleteDoc, updateDoc, collection } from "firebase/firestore";
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import timelinePlugin from '@fullcalendar/timeline';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import moment from 'moment';

import { db } from "../../../firebase";
import { Button } from "../components/Buttons";

import { IoMdRefresh } from "react-icons/io";
import { FaCheck } from "react-icons/fa";

export const Meetings = () => {
    const { eventId } = useParams();
    const [wishes, setWishes] = useState({
        count: 0,
        wishes: 0,
        wishlist: []
    });
    const [event, setEvent] = useState({});
    const [meetings, setMeetings] = useState([]);
    const [dominantsCalendarResources, setDominantsCalendarResources] = useState(null);
    const [dominantsCalendarEvents, setDominantsCalendarEvents] = useState(null);
    const [dominatedCalendarResources, setDominatedCalendarResources] = useState(null);
    const [dominatedCalendarEvents, setDominatedCalendarEvents] = useState(null);
    const [meetingAmplitudes, setMeetingAmplitudes] = useState({});
    const [meetingDuration, setMeetingDuration] = useState(15);
    const [loadingMatchMaking, setLoadingMatchMaking] = useState(false);
    const [displayMode, setDisplayMode] = useState('dominants');
    const [eventUsers, setEventUsers] = useState({});
    const [wishUsers, setWishUsers] = useState({});
    const [displaySlotModal, setDisplaySlotModal] = useState(false);
    const calendarRef = useRef(null);

    const matchMaking = async () => {
        const r = window.confirm("Are you sure you want to generate meetings? This will delete all unvalidated meetings.");
        let iterations = 0;
        if(!r) return;
        setLoadingMatchMaking(true);
        const slots = event.slots;
        const slotsArray = Object.keys(slots).sort(() => Math.random() - 0.5);
        let wishlist = [...wishes.wishlist].sort(() => Math.random() - 0.5);

        // Populate Available slots with wishes if dominant & dominated are available.
        console.log('Populate slots');
        let meetingItems = {};
        while(wishlist.length > 0 && iterations < 100) {
            let added = 0;
            for(const slot of slotsArray) {
                if(!meetingItems[slot]) meetingItems[slot] = [];
                for(const wish of wishlist) {
                    if (
                        !meetingItems[slot].find((meeting) => meeting.dominant === wish.dominant) &&
                        !meetingItems[slot].find((meeting) => meeting.dominated === wish.dominated) &&
                        !meetings.find((meeting) => meeting.dominant.id === wish.dominant && meeting.dominated.id === wish.dominated && meeting.status === 'validated') &&
                        !meetings.find((meeting) => meeting.dominant.id === wish.dominant && meeting.slot === slot && meeting.status === 'validated') &&
                        !meetings.find((meeting) => meeting.dominated.id === wish.dominated && meeting.slot === slot && meeting.status === 'validated') && 
                        !meetings.find((meeting) => meeting.dominant.id === wish.dominant && meeting.slot === slot && meeting.status === 'bloqued') &&
                        !meetings.find((meeting) => meeting.dominated.id === wish.dominated && meeting.slot === slot && meeting.status === 'bloqued')
                    ) {
                        added++;
                        meetingItems[slot].push(wish);
                        wishlist.splice(wishlist.indexOf(wish), 1);
                    }
                }
            }
            if(added === 0) iterations++;
        }

        // Remove collection meetings under events/{eventId} with status pending
        const meetingsRef = collection(db, 'events', eventId, 'meetings');
        const meetingsSnapshot = await getDocs(meetingsRef);
        meetingsSnapshot.forEach(async (doc) => {
            if(doc.data().status === 'pending') {
                await deleteDoc(doc.ref);
            }
        });
        

        let dominants = {};
        let dominated = {};
        for(const slot of Object.keys(meetingItems)) {
            for(const meeting of meetingItems[slot]) {
                if(!dominants[meeting.dominant]) {
                    const dominantRef = await getDoc(doc(db, 'users', meeting.dominant));
                    let dominant = dominantRef.data();
                    dominant.id = dominantRef.id;
                    const dominantCompany = await getDoc(dominant.company);
                    dominants[meeting.dominant] = {
                        id: dominant.id,
                        path: dominantRef.ref.path,
                        firstname: dominant.firstname,
                        lastname: dominant.lastname,
                        company: dominantCompany.data().name,
                    };
                }

                if(!dominated[meeting.dominated]) {
                    const dominatedRef = await getDoc(doc(db, 'users', meeting.dominated));
                    let dominatedUser = dominatedRef.data();
                    dominatedUser.id = dominatedRef.id;
                    const dominatedCompany = await getDoc(dominatedUser.company);
                    dominated[meeting.dominated] = {
                        id: dominatedUser.id,
                        // Path
                        path: dominatedRef.ref.path,
                        firstname: dominatedUser.firstname,
                        lastname: dominatedUser.lastname,
                        company: dominatedCompany.data().name
                    };
                }

                // Create document for meeting with dominant and dominated and slot, let firebase create id
                addDoc(collection(db, 'events', eventId, 'meetings'), {
                    dominant: dominants[meeting.dominant],
                    dominated: dominated[meeting.dominated],
                    slot: slot,
                    status: 'pending',
                    date: new Date()
                });
            }
        }
        getMeetings();
        setLoadingMatchMaking(false);
    }

    const getEvents = async () => {
        // List all documents in events/{eventId}/wishes/*
        const eventDoc = await getDoc(doc(db, 'events', eventId));
        let eventData = eventDoc.data();
        eventData.id = eventDoc.id;

        let tmpMeetingAmplitudes = {};
        let tmpMeetingDuration = 120;
        Object.keys(eventData.slots).forEach((slot) => {
            if(!tmpMeetingAmplitudes.min || tmpMeetingAmplitudes.min > eventData.slots[slot].begin.toDate()) {
                tmpMeetingAmplitudes.min = eventData.slots[slot].begin.toDate();
            }
            if(!tmpMeetingAmplitudes.max || tmpMeetingAmplitudes.max < eventData.slots[slot].end.toDate()) {
                tmpMeetingAmplitudes.max = eventData.slots[slot].end.toDate();
            }
            const slotDuration = moment(eventData.slots[slot].end.toDate()).diff(moment(eventData.slots[slot].begin.toDate()), 'minutes');
            if(slotDuration < tmpMeetingDuration) {
                tmpMeetingDuration = slotDuration;
            }
        });
        const duration = moment.duration(tmpMeetingDuration, 'minutes');
        // setMeetingDuration in format HH:mm:ss
        setMeetingDuration(`${duration.hours().toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}:00`);
        setMeetingAmplitudes(tmpMeetingAmplitudes);

        setEvent(eventData);
    }

    const getWishes = async () => {
        // List all documents in events/{eventId}/wishes/*
        const wishesRef = collection(db, 'events', eventId, 'wishlist');
        // Get all docs
        const wishesSnapshot = await getDocs(wishesRef);
        // Count the number of wishes
        let wishesCount = 0;
        let dominantsCount = 0;
        let tmpWishlist = [];
        let tmpWishUsers = {};
        for(const dominant of wishesSnapshot.docs) {
            const data = dominant.data();
            if (data.users && data.users.length > 0) {
                dominantsCount++;
                wishesCount+= data.users.length;

                const dominantRef = await getDoc(doc(db, 'users', dominant.id));
                let dominantData = dominantRef.data();
                dominantData.id = dominantRef.id;
                dominantData.company = (await getDoc(dominantData.company)).data().name;
                
                if(eventUsers[dominantData.email]) {
                    dominantData.date = eventUsers[dominantData.email].date;
                }
                tmpWishUsers[dominantData.id] = {...dominantData, type: 'dominant'};

                for(const user of data.users) {
                    const dominatedRef = await getDoc(doc(db, 'users', user.id));
                    let dominatedData = dominatedRef.data();
                    dominatedData.id = dominatedRef.id;
                    dominatedData.company = (await getDoc(dominatedData.company)).data().name;
                    if(eventUsers[dominatedData.email]) {
                        dominatedData.date = eventUsers[dominatedData.email].date;
                    }
                    tmpWishUsers[dominatedData.id] = {...dominatedData, type: 'dominated'};

                    tmpWishlist.push({
                        dominant: dominant.id,
                        dominated: user.id,
                    });
                };
            }
        };

        setWishUsers(tmpWishUsers);
        setWishes({
            count: dominantsCount,
            wishes: wishesCount,
            wishlist: tmpWishlist
        });
    };

    const getMeetings = async () => {
        const meetingsRef = collection(db, 'events', eventId, 'meetings');
        const meetingsSnapshot = await getDocs(meetingsRef);
        let tmpMeetings = [];
        meetingsSnapshot.forEach((doc) => {
            let meeting = doc.data();
            meeting.id = doc.id;
            tmpMeetings.push(meeting);
        });
        setMeetings(tmpMeetings);
    }

    const sendMeetings = async (meetingIds) => {
        let baseUrl = "https://us-central1-plateforme-matchmaking-fd.cloudfunctions.net/api";
        if (document.location.hostname === "admin.fd.com") baseUrl = "http://127.0.0.1:5001/plateforme-matchmaking-fd/us-central1/api";
        // send eventid and meetingIds to the api /meetings/send with fetch and post
        fetch(`${baseUrl}/meetings/send`, {
            method: 'POST',
            body: JSON.stringify({
                event: eventId,
                meetings: meetingIds
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
        .then((data) => {
            if(data.success) {
                alert('Emails sent');
            } else {
                alert('An error occured while sending emails');
            }
        }).catch((error) => {
            console.error({ error });
            alert('An error occured while sending emails');
        });
    }

    const getEventUsers = async () => {
        const eventUsersRef = collection(db, 'events', eventId, 'users');
        const eventUsersSnapshot = await getDocs(eventUsersRef);
        let tmpEventUsers = {};
        eventUsersSnapshot.forEach((doc) => {
            let eventUser = doc.data();
            eventUser.id = doc.id;
            tmpEventUsers[eventUser.id] = eventUser;
        });
        setEventUsers(tmpEventUsers);
    }

    useEffect(() => {
        getEvents();
        getEventUsers();
        getWishes();
        getMeetings();

        // eslint-disable-next-line
    }, []);

    const handleMeetingDrop = async (arg) => {
        let newDoc = {};
        for(const slot of Object.keys(event.slots)) {
            if(moment(arg.event.start).format('YYYY-MM-DD HH:mm:ss') === moment(event.slots[slot].begin.toDate()).format('YYYY-MM-DD HH:mm:ss')) {
                newDoc.slot = slot;
            }
        }

        if(arg.newResource && arg.oldResource.id !== arg.newResource.id) {
            newDoc.dominant = {
                id: arg.newResource.extendedProps.dominantId,
                path: doc(db, 'users', arg.newResource.extendedProps.dominantId).path,
                company: arg.newResource.extendedProps.company,
                firstname: arg.newResource.extendedProps.firstname,
                lastname: arg.newResource.extendedProps.lastname
            };
        }

        // Check if dominant or dominated is available in the slot
        let block = false;
        meetings.map((meeting) => {
            if(block === false && meeting.slot === newDoc.slot && (arg.event.extendedProps.dominantId === meeting.dominant.id || arg.event.extendedProps.dominatedId === meeting.dominated.id)) {
                block = true;
                alert('This slot is already taken by dominant or dominated');
            }
            return true;
        });

        if(block === false) {
            if(newDoc.slot || newDoc.dominant) {
                // Update the slot of the meeting
                const meetingRef = doc(db, 'events', eventId, 'meetings', arg.event.extendedProps.meetingId);
                await updateDoc(meetingRef, newDoc);
            }
        }
        getMeetings();
    }

    const deleteMeeting = async (meetingId) => {
        if(window.confirm("Are you sure you want to delete this meeting?") === false) return;

        const meetingRef = doc(db, 'events', eventId, 'meetings', meetingId);
        await deleteDoc(meetingRef);
        getMeetings();
    }
    
    const validateMeetings = async () => {
        if(window.confirm("Are you sure you want to validate all meetings? Email will be sent to users.") === false) return;

        const meetingsRef = collection(db, 'events', eventId, 'meetings');
        const meetingsSnapshot = await getDocs(meetingsRef);
        let meetingsToSend = [];
        for(const doc of meetingsSnapshot.docs) {
            if(doc.data().status === 'pending') {
                await updateDoc(doc.ref, { status: 'validated' });
                meetingsToSend.push(doc.id);
            }
        };
        if(meetingsToSend.length > 0) sendMeetings(meetingsToSend);
        getMeetings();
    }

    useEffect(() => {
        if(meetings && Array.isArray(meetings) && event && event.slots) {
            let dominantsResources = [];
            let dominantsEvents = [];
            let dominatedResources = [];
            let dominatedEvents = [];

            if(wishUsers) {
                Object.keys(wishUsers).map(eventUserId => {
                    let eventUser = wishUsers[eventUserId];
                    if(eventUser.type === 'dominant') {
                        dominantsResources.push({
                            id: eventUser.id,
                            title: eventUser.company,
                            date: eventUser.date,
                            extendedProps: {
                                dominantId: eventUser.id,
                                firstname: eventUser.firstname,
                                lastname: eventUser.lastname,
                                company: eventUser.company,
                                date: eventUser.date,
                                unaffectedMeetings: []
                            }
                        });
                    } else if (eventUser.type === 'dominated') {
                        dominatedResources.push({
                            id: eventUser.id,
                            title: eventUser.company,
                            date: eventUser.date,
                            extendedProps: {
                                dominantId: eventUser.id,
                                firstname: eventUser.firstname,
                                lastname: eventUser.lastname,
                                company: eventUser.company,
                                date: eventUser.date,
                                unaffectedMeetings: []
                            }
                        });
                    }
                    return true;
                });
            }

            meetings.map(meeting => {
                // Add the meeting to the events
                dominantsEvents.push({
                    resourceId: meeting.dominant.id,
                    title: meeting.dominated.company,
                    extendedProps: {
                        meetingId: meeting.id,
                        dominantId: meeting.dominant.id,
                        dominatedId: meeting.dominated.id,
                        slot: meeting.slot,
                        firstname: meeting.dominated.firstname,
                        lastname: meeting.dominated.lastname,
                        company: meeting.dominated.company,
                        status: meeting.status
                    },
                    start: event.slots[meeting.slot].begin.toDate(),
                    end: event.slots[meeting.slot].end.toDate()
                });

                dominatedEvents.push({
                    resourceId: meeting.dominated.id,
                    title: meeting.dominant.company,
                    extendedProps: {
                        meetingId: meeting.id,
                        dominantId: meeting.dominant.id,
                        dominatedId: meeting.dominated.id,
                        slot: meeting.slot,
                        firstname: meeting.dominant.firstname,
                        lastname: meeting.dominant.lastname,
                        company: meeting.dominant.company,
                        status: meeting.status
                    },
                    start: event.slots[meeting.slot].begin.toDate(),
                    end: event.slots[meeting.slot].end.toDate()
                });

                return true;
            });

            // If wish is not in the meetings, add it to the unaffectedMeetings extendedProps
            dominantsResources.map((resource, i) => {
                wishes.wishlist.map((wish) => {
                    if(wish.dominant === resource.id && !meetings.find((meeting) => meeting.dominant.id === wish.dominant && meeting.dominated.id === wish.dominated)) {
                        dominantsResources[i].extendedProps.unaffectedMeetings.push(wish.dominated);
                    }
                    return true;
                });
                return true;
            });

            dominatedResources.map((resource, i) => {
                wishes.wishlist.map((wish) => {
                    if(wish.dominated === resource.id && !meetings.find((meeting) => meeting.dominant.id === wish.dominant && meeting.dominated.id === wish.dominated)) {
                        dominatedResources[i].extendedProps.unaffectedMeetings.push(wish.dominant);
                    }
                    return true;
                });
                return true;
            });
            
            setDominantsCalendarResources(dominantsResources);
            setDominantsCalendarEvents(dominantsEvents);
            setDominatedCalendarResources(dominatedResources);
            setDominatedCalendarEvents(dominatedEvents);
        }
    }, [meetings, event, wishUsers, wishes]);

    const blockSlot = async (arg) => {
        const slot = Object.keys(event.slots).find((slot) => moment(event.slots[slot].begin.toDate()).format('YYYY-MM-DD HH:mm:ss') === moment(arg.date).format('YYYY-MM-DD HH:mm:ss'));
        let doc = {};
        if(displayMode === 'dominants') {
            doc = {
                dominant: {
                    id: arg.resource.extendedProps.dominantId,
                    company: arg.resource.extendedProps.company,
                    firstname: arg.resource.extendedProps.firstname,
                    lastname: arg.resource.extendedProps.lastname,
                    path: `users/${arg.resource.extendedProps.dominantId}`
                },
                dominated: {
                    id: null,
                    company: 'Blocked slot',
                    firstname: 'blocked',
                    lastname: 'slot',
                },
                slot: slot,
                status: 'bloqued',
                date: new Date()
            };
        } else {
            doc = {
                dominant: {
                    id: null,
                    company: 'Blocked slot',
                    firstname: 'blocked',
                    lastname: 'slot',
                },
                dominated: {
                    id: arg.resource.extendedProps.dominatedId,
                    company: arg.resource.extendedProps.company,
                    firstname: arg.resource.extendedProps.firstname,
                    lastname: arg.resource.extendedProps.lastname,
                },
                slot: slot,
                status: 'bloqued',
                date: new Date()
            };
        }
        
        addDoc(collection(db, 'events', eventId, 'meetings'), doc);
        getMeetings();
    }

    const addMeeting = async (arg, resource, attendee) => {
        const slot = Object.keys(event.slots).find((slot) => moment(event.slots[slot].begin.toDate()).format('YYYY-MM-DD HH:mm:ss') === moment(arg.date).format('YYYY-MM-DD HH:mm:ss'));
        let doc = {};
        if(displayMode === 'dominants') {
            doc = {
                dominant: {
                    id: arg.resource.extendedProps.dominantId,
                    company: arg.resource.extendedProps.company,
                    firstname: arg.resource.extendedProps.firstname,
                    lastname: arg.resource.extendedProps.lastname,
                    path: `users/${arg.resource.extendedProps.dominantId}`
                },
                dominated: {
                    id: attendee.id,
                    company: attendee.company,
                    firstname: attendee.firstname,
                    lastname: attendee.lastname,
                    path: `users/${attendee.id}`
                },
                slot: slot,
                status: 'pending',
                date: new Date()
            };
        } else {
            doc = {
                dominant: {
                    id: attendee.id,
                    company: attendee.company,
                    firstname: attendee.firstname,
                    lastname: attendee.lastname,
                    path: `users/${attendee.id}`
                },
                dominated: {
                    id: resource.dominantId,
                    company: resource.company,
                    firstname: resource.firstname,
                    lastname: resource.lastname,
                    path: `users/${resource.dominantId}`
                },
                slot: slot,
                status: 'pending',
                date: new Date()
            };
        }

        addDoc(collection(db, 'events', eventId, 'meetings'), doc);
        getMeetings();
    }

    const SlotModal = ({display}) => {
    
        const handleBlockSlot = () => {
            if(window.confirm(`Are you sure you want to block this slot for ${display.resource.title} ?`) === false) return;
            blockSlot(display);
            setDisplaySlotModal(false);
        }

        const handleAddSlot = (resource, attendee) => {
            if(window.confirm(`Are you sure you want to add a meeting for ${display.resource.title} ?`) === false) return;
            addMeeting(display, resource, attendee);
            setDisplaySlotModal(false);
        }

        if(display) return (
            <div className="fixed z-50 inset-0 flex items-center justify-center overflow-hidden">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <div className="bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Meeting adjustment
                        </h3>
                        <div className="mt-2">
                            <ul className="divide-y divide-gray-200 shadow-md">
                                <li className="flex justify-between items-center user-card border-b border-grey-200">
                                    <button className="w-full text-left p-3" onClick={handleBlockSlot}>Block Slot</button>
                                </li>
                                {display.resource.extendedProps.unaffectedMeetings && display.resource.extendedProps.unaffectedMeetings.length > 0 && display.resource.extendedProps.unaffectedMeetings.map((meeting, i) => (
                                    <li className="flex justify-between items-center user-card border-b border-grey-200" key={i}>
                                        <button className="w-full text-left p-3" onClick={() => handleAddSlot(display.resource.extendedProps, wishUsers[meeting])}>{wishUsers[meeting].company}</button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" 
                        >
                            Accept
                        </button>
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-grey-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" 
                            onClick={() => setDisplaySlotModal(false)}
                        >
                            Cancel 
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container mx-auto">
            <div className="flex justify-between">
                <div>
                    <h1 className="text-5xl">{event.name} : Meetings</h1>
                    <p>{wishes.count} dominants made {wishes.wishes} whishes</p>
                    {meetings.length > 0 && <p>{meetings.length} meetings have been generated</p>}
                </div>
                <div className="flex gap-2">
                    <div>
                        <Button onClick={matchMaking} className="flex items-center font-bold bg-red-400"><IoMdRefresh className="mr-2" /> {loadingMatchMaking ? "Generating..." : "MatchMaking"}</Button>
                    </div>
                    <div>
                        {meetings && Array.isArray(meetings) && meetings.find((meeting) => meeting.status === 'pending') && <Button onClick={validateMeetings} className="flex items-center font-bold bg-green-400"><FaCheck className="mr-2" />Validate Meetings</Button>}
                    </div>
                </div>
            </div>
            <div className="flex gap-2 mt-4">
                <Button className={displayMode === 'dominants' ? 'bg-blue' : 'bg-grey-400'} onClick={() => setDisplayMode('dominants')}>Dominants</Button>
                <Button className={displayMode === 'dominated' ? 'bg-blue' : 'bg-grey-400'} onClick={() => setDisplayMode('dominated')}>Dominated</Button>
            </div>

            <div className="flex w-full mt-4">
                    {event && event.startDate && (
                        <>
                            <SlotModal display={displaySlotModal} />
                            <FullCalendar
                                height="auto"
                                ref={calendarRef}
                                plugins={[interactionPlugin, timelinePlugin, resourceTimelinePlugin]}
                                initialView="resourceTimelineDay"
                                initialDate={moment(event.startDate.toDate()).toDate()}
                                scrollTime={moment(event.startDate.toDate()).format('HH:mm:ss')}
                                resources={displayMode === 'dominants' ? dominantsCalendarResources : dominatedCalendarResources}
                                resourceOrder="-date"
                                events={displayMode === 'dominants' ? dominantsCalendarEvents : dominatedCalendarEvents}
                                slotMinWidth={60}
                                slotDuration={meetingDuration}
                                slotMinTime={moment(meetingAmplitudes.min).format('HH:mm:ss')}
                                slotMaxTime={moment(meetingAmplitudes.max).format('HH:mm:ss')}
                                locale='fr-FR'
                                editable={true}
                                eventDrop={handleMeetingDrop}
                                dateClick={setDisplaySlotModal}
                                resourceLabelContent = {(arg) => {
                                    return (
                                        <div className="flex flex-col">
                                            <div><b>{arg.resource.title}</b></div>
                                            <div className="text-xs truncate" style={{ fontSize: '0.8rem' }}><i>{arg.resource.extendedProps.firstname} {arg.resource.extendedProps.lastname}</i></div>
                                            <div>
                                                {arg.resource.extendedProps.unaffectedMeetings && arg.resource.extendedProps.unaffectedMeetings.length > 0 && (
                                                    <div className="flex g-1">
                                                        {arg.resource.extendedProps.unaffectedMeetings.map((meeting, i) => (
                                                            <div className="rounded bg-red-400 p-2 pb-1 text-white mr-1 leading-4" key={i}>
                                                                <b className="m-0">{wishUsers[meeting].company}</b><br />
                                                                <small>{wishUsers[meeting].firstname} {wishUsers[meeting].lastname}</small>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                }}
                                eventContent={(arg) => {
                                    return (
                                        <div className={`p-1 m-1 ${arg.event.extendedProps.status === 'validated' ? 'bg-green-400' : arg.event.extendedProps.status === 'bloqued' ? 'bg-red-400' : 'bg-orange'} bg-green-400 rounded-md`} onClick={() => deleteMeeting(arg.event.extendedProps.meetingId)}>
                                            <b>{arg.event.title}</b>
                                            <div className="text-xs truncate" style={{ fontSize: '0.8rem' }}><i>{arg.event.extendedProps.firstname} {arg.event.extendedProps.lastname}</i></div>
                                        </div>
                                    );
                                }}
                            />
                            <style>
                                {`
                                    .fc-license-message {
                                        display: none;
                                    }
                                    .fc-event {
                                        background-color: transparent;
                                        border: none;
                                        border-radius: 10px;
                                    }
                                `}
                            </style>
                        </>
                    )}
                </div>
        </div>
    );
};